import React, { useEffect, useState } from 'react';
import Home from './Components/Pages/Home';
import AuthPage from './Components/Pages/auth';
import Footer from './Components/Pages/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme } from './utils/themes';

const App: React.FC = () => {

  const [title, setTitle] = useState("PingMe");

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = title;
  }, [title]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div>
          <AuthPage />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
