import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setLogedOut } from "../../redux/reducers/logedIn";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import data from './../../utils/languages.json';


const LogoutButton = () => {
  const { logout } = useAuth0();
  const language = useSelector((state: RootState) => state.user.language_name);
  const dispatch = useDispatch();

  return (
      <div onClick={() => {
        dispatch(setLogedOut());
        logout({ returnTo: window.location.origin });
      }}>  {data.ProfileMenu.logout[language]} </div>
  );
};

export default LogoutButton;