import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';

interface UserReducer {
    roles: string[] | undefined,
    token: string | undefined,
    language: number,
    language_name: string,
    userName: string,
}

const initialState: UserReducer = {
    roles: undefined,
    token: undefined,
    language: 1,
    language_name: 'English',
    userName: 'P',
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUserRole: (state, action) => {
            state.roles = action.payload
        },
        saveToken: (state, action) => {
            state.token = action.payload
        },
        changeLanguage: (state, action) => {
            state.language = action.payload
            if(action.payload === 1)
                state.language_name = 'English';
            else if(action.payload === 2)
                state.language_name = 'Norsk'
            else state.language_name = 'Norsk'
        },
        changeUserName: (state, action) => {
            state.userName = action.payload
        },
        clearAll: (state) => {
            state.roles = undefined;
            state.token = undefined;
            state.language = 1;
            state.userName = 'P';
            state.language_name = 'English'
        },
    }
})
export const {saveUserRole, 
              saveToken, 
              changeLanguage, 
              changeUserName,
              clearAll} = userSlice.actions;
export default userSlice.reducer;