import React, { useEffect, useState } from 'react';
import { TransponderProps } from './Types';
import './../../CSS/Transponders.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { saveChosenOneTransponderDetails, saveChosenTransponderFromMenu, setChanged, setOneChanged } from '../../redux/reducers/transponder';
import { useNavigate } from 'react-router-dom';
import MissingButton from './missingButton';
import { parseDateTime } from '../../utils/parser';
import { Api } from '../../api';
import data from './../../utils/languages.json';

const Transponder = (props:TransponderProps) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const changed = useSelector((state: RootState) => state.transponder.oneChanged);
    const transponder = useSelector((state: RootState) => state.transponder.chosenOneTransponderDetails);
    const language = useSelector((state: RootState) => state.user.language_name);
    const [transponderMissing, setTransponderMissing] = useState(props.transponder.missingAt !== null);
    const [missingAt, setMissingAt] = useState(props.transponder.missingAt)
    const [doNotFetch, setDoNotFetch] = useState(false)

    useEffect(() => {
      if(!doNotFetch){
        fetchTrans().catch(console.error);
        setDoNotFetch(true);
      }
    });

    const goToTransponder = () => {
      dispatch(saveChosenTransponderFromMenu({label: props.transponder.name, id: props.transponder.id}));
      navigate("/Transponder");
    }

    const fetchTrans = async () => {
      if(!doNotFetch){
        const transponderFetched = await Api.getTransponderDetails(props.token, props.transponder.id);
        if(transponderFetched.status === 200){
          dispatch(saveChosenOneTransponderDetails(transponderFetched.data));
          setTransponderMissing(transponderFetched.data.missingAt !== null);
          setMissingAt(transponderFetched.data.missingAt);
        }
        else {
        }
        dispatch(setChanged(true));
      }
      
    }

    const update = () => {
      fetchTrans();
      setDoNotFetch(false);
      return;
    }

    return(
      <>
        <tr>
          <td className='linkPointer' onClick={goToTransponder}> {props.transponder.name} </td>
          <td> {props.transponder.public ? data.Transponder.Visible[language] : data.Transponder.NotVisible[language]} </td>
          <td> {props.transponder.hardwareId} </td>
          <td> {missingAt !== null ? data.Transponder.Yes[language] + ' ' + data.Transponder.In[language] + ' ' + parseDateTime(missingAt) : data.Transponder.No[language]} </td>
          <td> {props.transponder.detections.length > 0 ? data.TransInfo[props.transponder.detections[0].type+'_'][language] : data.Transponder.NoDetections[language]} </td>
          <td> {props.transponder.detections.length > 0 ? parseDateTime(props.transponder.detections[0].detectedAt) : data.Transponder.NoDetections[language]} </td>
        </tr>
        <tr> <td colSpan={6}> 
                <MissingButton id={props.transponder.id} current={transponderMissing}
                 text={transponderMissing ? data.ReportMissing.ReportFound[language] : data.ReportMissing.ReportMissing[language]}
                 functionToApply={update} /> 
              </td> </tr>
        <tr> <td colSpan={6}> <hr /> </td> </tr>
      </>
    );
  }

export default Transponder;

