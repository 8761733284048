import React from "react";
import { Form } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import {
    IDealerInformation,
    IStepsChildren,
} from "../../../Types/Registration";
import PrevNext from "../PrevNext";
import InputWithLabel from "../InputWithLabel";
import {FormFields} from "./DealerInformationUtils";


const DealerInformation = (props: IStepsChildren<IDealerInformation>) => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const { current, stepsLength, prev, next, data, setData } = props;
    const onFinish = (values: IDealerInformation) => {
        setData(values);
        next();
        //console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log("Failed:", errorInfo);
    };

    return (
        <div>
            <Form
                name="basic"
                initialValues={{ 
                    remember: true, 
                    dealerName:user?.name,
                    dealerEmail:user?.email,
                    dealerPhone:user?.phone_number,
                    ...data 
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="container">
                    <div className="row">
                        {FormFields.map((item) => (
                            <div key={item.name} className="col-md-6">
                                <InputWithLabel {...item} />
                            </div>
                        ))}
                    </div>
                </div>
                <PrevNext current={current} prev={prev} stepsLength={stepsLength} />
            </Form>
        </div>
    );
};

export default DealerInformation;
