import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "antd/dist/antd.min.css"
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import {store, persistor} from "./redux/store/store";

ReactDOM.render(
  <Auth0Provider
    domain="osac.eu.auth0.com"
    clientId="V15t3cG1i7PiJYZMgSGJx1znmUMyekdU"
    redirectUri={"https://app.pingme.no/"}
    audience="https://osac.eu.auth0.com/api/v2/"
    scope="read:current_user update:current_user_metadata"
    useCookiesForTransactions={true}
    cacheLocation="localstorage"
  >

      <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App/>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
