import {useEffect, useState} from "react";
import {Steps} from "antd";
import "../../CSS/sellerRegistration.css";
import {
    IBoatInformation,
    IBuyerInformation,
    IDealerInformation,
    ISteps,
} from "../../Types/Registration";
import DealerInformation from "../SellerRegistration/DealerInformation/DealerInformation";
import BoatInformation from "../SellerRegistration/BoatInformation/BoatInformation";
import BuyerInformation from "../SellerRegistration/BuyerInformation/BuyerInformation";
import Confirmation from "../SellerRegistration/Confirmation/Confirmation";
import {dynamicFields} from "../SellerRegistration/BuyerInformation/BuyerUtils";
import { RootState } from "../../redux/store/store";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import { putConfirmed } from "../../redux/reducers/boat";
import data from '../../utils/languages.json';

const {Step} = Steps;
const stepsLength = 4;

const SellerRegistration = () => {
    const [current, setCurrent] = useState<number>(0);
    const [dealerInformation, setDealerInformation] = useState<IDealerInformation>();
    const [boatInformation, setBoatInformation] = useState<any>();
    const [buyerInformation, setBuyerInformation] = useState<IBuyerInformation>();
    const token = useSelector((state: RootState) => state.user.token);
    const language = useSelector((state: RootState) => state.user.language_name);
    const dispatch = useDispatch();

    // To be done after clicking the next button
    const next = () => {
        setCurrent(current + 1);
    };

    // To be done after clicking the prev button
    const prev = () => {
        setCurrent(current - 1);
    };

    const changeCurrentTab = (current: number) => {
        setCurrent(current)
    }

    // To be done when clicking on confirm button. All informations to be sent are collected
    // and added to InfoToBeSent then the registration API is called
    const done = () => {
        let transponders : Array<{ hardwareId: any; partNumber: string; serialNumber: string; gearCode: string; isPublic: boolean; }> = []; 
        buyerInformation?.transponder?.map((transponder:any) => {
            transponders.push({
                "hardwareId": transponder.decodeId,
                "partNumber": transponder.partNumber,
                "serialNumber": transponder.serialNumber,
                "gearCode": transponder.gearCode,
                "isPublic": transponder.visible,
              })
          })
          let transceivers : Array<{ id: any; partNumber: any; serialNumber: any; }> = []; 
        buyerInformation?.transceiver?.map((transceiver:any) => {
            transceivers.push({
              "id": transceiver.uniqueId, 
              "partNumber": transceiver.partNumber,
              "serialNumber": transceiver.serialNumber,
              })
          })
          let transducers : Array<{ id: any; partNumber: any; serialNumber: any; }> = []; 
        buyerInformation?.transducerOnBoat?.map((transducer:any) => {
            transducers.push({
              "id": transducer.uniqueId, 
              "partNumber": transducer.partNumber,
              "serialNumber": transducer.serialNumber,
              })
          })
          let chargers : Array<{ type: any; versionNumber: any; }> = []; 
        buyerInformation?.charger?.map((charger:any) => {
            chargers.push({
              "type": charger.type,
              "versionNumber": charger.versionNumber,
              })
          })
          let processingUnits : Array<{ id: any; partNumber: any; serialNumber: any; }> = []; 
        buyerInformation?.processingUnit?.map((processingUnit:any) => {
          processingUnits.push({
                "id": processingUnit.uniqueId,
                "partNumber": processingUnit.partNumber,
                "serialNumber": processingUnit.serialNumber,
              })
          })

          let maps : Array<{ area: any; versionNumber: any; }> = []; 
        buyerInformation?.map?.map((map:any) => {
            maps.push({
                "area": map.area,
                "versionNumber": map.versionNumber,
              })
          })
          let gpss : Array<{ type: any; serialNumber: any; }> = []; 
        buyerInformation?.gps?.map((gps:any) => {
            gpss.push({
                "type": gps.type,
                "serialNumber": gps.serialNumber,
              })
          })

          let gpsOnBoat : Array<{ brand: any; versionNumber: any; }> = []; 
          buyerInformation?.existingGpsOnBoat?.map((gps:any) => {
            gpsOnBoat.push({
                  "brand": gps.brand,
                  "versionNumber": gps.versionNumber,
                })
            })
            let chargePlotters : Array<{ type: any; versionNumber: any; }> = []; 
            buyerInformation?.chartPlotter?.map((chargePlotter:any) => {
              chargePlotters.push({
                    "type": chargePlotter.brand,
                    "versionNumber": chargePlotter.versionNumber,
                  })
              })
        const InfoToBeSent = {
            "vesselId": boatInformation?.boat.key,
            "dealerName": dealerInformation?.dealerName,
            "dealerEmail": dealerInformation?.dealerEmail,
            "dealerPhone": dealerInformation?.dealerPhone,
            "companyName": boatInformation?.boatOwner,
            "companyEmail": boatInformation?.companyEmail,
            "companyPhone": boatInformation?.companyPhone,
            "fleetManagerName": boatInformation?.fleetManagerName,
            "fleetManagerEmail": boatInformation?.fleetManagerEmail,
            "fleetManagerPhone": boatInformation?.fleetManagerPhone,
            "customerName": buyerInformation?.buyerName,
            "customerEmail": buyerInformation?.buyerEmail,
            "customerPhone": buyerInformation?.buyerPhone,
            "transponders": transponders,
            "transceivers": transceivers,
            "transducers": transducers,
            "chargers": chargers,
            "processingUnits": processingUnits,
            "maps": maps,
            "gps": gpss,
            "gpsOnBoat": gpsOnBoat,
            "chargePlotters": chargePlotters,
          }

        var axios = require("axios").default;
         axios.post("https://api.pingme.no/sales", InfoToBeSent, {headers: {'authorization':  `Bearer ${token}`}})
          .then( () => {
            dispatch(putConfirmed(true))
      
          }).catch(function (e) {
            dispatch(putConfirmed(false))
          });
    }

    const steps: ISteps[] = [
        {
            title: data.SellerApp["Dealer Information"][language],
            content: (
                <DealerInformation
                    current={current}
                    stepsLength={stepsLength}
                    data={dealerInformation}
                    setData={setDealerInformation}
                    prev={prev}
                    next={next}
                />
            ),
        },
        {
            title: data.SellerApp["Boat Information"][language],
            content: (
                <BoatInformation
                    current={current}
                    stepsLength={stepsLength}
                    data={boatInformation}
                    setData={setBoatInformation}
                    prev={prev}
                    next={next}
                />
            ),
        },
        {
            title: data.SellerApp["Buyer Information"][language],
            content: (
                <BuyerInformation
                    current={current}
                    stepsLength={stepsLength}
                    data={buyerInformation}
                    setData={setBuyerInformation}
                    prev={prev}
                    next={next}
                />
            ),
        },
        {
            title: data.SellerApp.Confirmation[language],
            content: (
                <Confirmation
                    dealerInformation={dealerInformation}
                    boatInformation={boatInformation}
                    buyerInformation={buyerInformation}
                    prev={prev}
                    done={done}
                    current={current}
                    stepsLength={stepsLength}
                    changeCurrentTab={changeCurrentTab}
                />
            ),
        },
    ];

    useEffect(() => {
        dispatch(putConfirmed(false))
      }, []);

    return (
        <div className="registration-container pb-5">
            <Steps current={current}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title}/>
                ))}
            </Steps>
            <div className="steps-content steps-form">{steps[current].content}</div>
        </div>
    );
};

export default SellerRegistration;
