import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#fcf4e8',
      main: '#faebd7',
      dark: '#030F37',
      contrastText: '#2344a9',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    info:{
      main: '#ff4d4d',
      dark: '#309B4A',
      contrastText: '#fff7cc',
    },
  },
});