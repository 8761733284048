import React, { useEffect, useState } from 'react';
import { Boat__, detection, Transponder_ } from './../SubComponents/Types';
import Map_ from './../SubComponents/map';
import { MapProps } from './../SubComponents/Types';
import './../../CSS/Boats.css';
import './../../CSS/BoatInfo.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button } from '@mui/material';
import { theme } from '../../utils/themes';
import { RootState } from '../../redux/store/store';
import { saveBoatId, saveBoatName, saveBoatPos, saveMapCenter } from "./../../redux/reducers/boatMap";
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../api';
import { saveRecievedBoatDetails } from './../../redux/reducers/boat';
import { saveChosenTransponderDetails, setChanged } from '../../redux/reducers/transponder';
import MissingButton from '../SubComponents/missingButton';
import { parseDateTime, doNotFetch, updateDoNotFetch, findGradesMinutes } from '../../utils/parser';
import data from './../../utils/languages.json';

let boat:Boat__ | undefined; 

const BoatInfo = () =>  {
    const state = useLocation();
    let navigate = useNavigate();
    const chosenTransponder = useSelector((state: RootState) => state.transponder.transponderId);
    const changed = useSelector((state: RootState) => state.transponder.changed);
    const transponder = useSelector((state: RootState) => state.transponder.chosenTransponderDetails);
    const boatId = useSelector((state: RootState) => state.boats.boatId);
    const language = useSelector((state: RootState) => state.user.language_name);
    const [b, setB] = useState(boatId);
    const [sameBoate, setSameBoat] = useState(false);
    let detectionsToShow : detection[] = [];
    let mapProps : MapProps | undefined;
    const [transponderMissing, setTransponderMissing] = useState(transponder?.missingAt !== null);
    const [mapComp, setMapComp] = useState(<></>);
    const [loading, setLoading] = useState(boatId===undefined ? true : false);
    const boatName = useSelector((state: RootState) => state.boats.boatName);
    let boat = useSelector((state: RootState) => state.boats.boatDetails);
    const token = useSelector((state: RootState) => state.user.token);
    const dispatch = useDispatch();
    
    if(b!=boatId){
        setB(boatId)
        setSameBoat(false)
    }

    const fetchBoat = async () => {
        try{
            updateDoNotFetch(true)
            setLoading(true);
            const recievedBoat = await Api.getBoatDetails(token, boatId);
            if(recievedBoat.status === 200){
                updateDoNotFetch(true)
                setLoading(false);
                if(recievedBoat.data !== boat){
                    dispatch(saveRecievedBoatDetails(recievedBoat.data));
                    updateDoNotFetch(false);
                    updateMapProps();
                    setSameBoat(true);
                }
                else{
                    setSameBoat(false);
                }
            }
            else {
                updateDoNotFetch(true);
                setLoading(false);
            }
        }
        catch{
            updateDoNotFetch(true);
            setLoading(false);
            return;
        }
      }

      const fetchTrans = async () => {
        dispatch(setChanged(false));
        if(chosenTransponder !== undefined){
            const transponderFetched = await Api.getTransponderDetails(token, chosenTransponder);
            dispatch(saveChosenTransponderDetails(transponderFetched.data));
            if(transponderFetched.data.missingAt !== null){
                setTransponderMissing(true);
            }
            else{
                setTransponderMissing(false);
            }
        }
        else{
            dispatch(saveChosenTransponderDetails(undefined));
        }
      }

    useEffect(() => {
        //If the boat is the same saved boat, it will not make a new api call to get its information
        if(!sameBoate && !doNotFetch)
            fetchBoat().catch();
        if(changed)
            fetchTrans().catch();
        //fetchdetections().catch(console.error);
      });

    const sendTranspondersInfo = () => {
        fetchBoat().catch();
        navigate("/Transponders", {state: {transponders: boat?.transponders, boatName: boat?.vesselName}});
    }

    const sendVoyageInfo = () => {
        navigate("/Voyage", {state: boat?.id})
        if(boat !== undefined){
            dispatch(saveBoatName(boat.vesselName));
            dispatch(saveBoatId(boat.id));
            dispatch(saveBoatPos(boat.lastKnownPosition));
        }
    }

    const showDetections = () => {
        let prevType = '';
        detectionsToShow = [];
        if(transponder?.detections.length !== undefined && transponder?.detections.length > 0){
            transponder?.detections.slice(0,1).map(detection => {
                if(prevType !== detection.type){
                    detectionsToShow.push(detection);
                    prevType = detection.type;
                }
            })
        }
    }

    const updateMapProps = () => {
        if(boat?.lastKnownPosition != null && boat !== undefined){
            mapProps = {
                markers: [{
                    latitude: boat?.lastKnownPosition.latitude,
                    longitude:  boat?.lastKnownPosition.longitude,
                    Name: boat.vesselName,
                    ID: boat.id,
                    type: 'boat',
                }, ],
                center_lat: boat?.lastKnownPosition.latitude,
                center_long: boat?.lastKnownPosition.longitude,
                containerSize: 2,
            }
            if(typeof boat.transponders !== 'number'){
                boat.transponders.forEach(function (value) {
                    if(value.detections!==undefined){
                        if(value.detections.length > 0){
                            if(value.detections[0].type === 'FOUND' || value.detections[0].type === 'SET'){
                                mapProps?.markers.push({
                                    latitude: value.detections[0].position.latitude,
                                    longitude: value.detections[0].position.longitude,  
                                    Name: value.name,
                                    ID: value.id,
                                    type: 'transponder',
                                })
                            }
                            // Her bestemmer jeg om den er i sjøen, jeg ser bare på de to siste
                            else if(value.detections[0].type === 'SINKING'){
                                if(value.detections[1].type === 'FOUND' || value.detections[1].type === 'SET')
                                    mapProps?.markers.push({
                                        latitude: value.detections[1].position.latitude,
                                        longitude: value.detections[1].position.longitude,  
                                        Name: value.name,
                                        ID: value.id,
                                        type: 'transponder',
                                    })
                            }
                        }
                    }
                });
            }
            setMapComp(<Map_ {...mapProps}/>);
            dispatch(saveMapCenter({lat:boat?.lastKnownPosition.latitude, long: boat?.lastKnownPosition.longitude}))
        }
        else{
            setMapComp(<></>);
            mapProps = undefined;
        }
    }

    const fetchAll = () => {
        fetchBoat();
        fetchTrans();
    }

    if(loading){
        return(<p> {data.Loading.Boat[language]} </p>)
    }
    return(boat !== undefined ? (
        <div className='container_boat'>
            <div className='info'>
                <h3> {boat.vesselName} {data.BoatInfo.GeneralInfo[language]} </h3>
                <p> <b>{data.BoatInfo.BoatName[language]}:</b> {boat.vesselName}
                <br /> <b>{data.BoatInfo.Signal[language]}:</b> 
                <br /> <b>{data.BoatInfo.IMO[language]}:</b>
                <br /> <b>{data.BoatInfo.Owner[language]}:</b>  {boat.owner.name}
                <br /> <b>{data.BoatInfo.Phone[language]}:</b>  {boat.owner.contactInfo.phone}
                <br /> <b>{data.BoatInfo.Email[language]}:</b>  {boat.owner.contactInfo.email}
                <br /> <br />

                <h5>{data.BoatInfo.FleetManager[language]}</h5>  
                <b>{data.BoatInfo.Name[language]}:</b> {boat.fleetManagerContactInfo.name} 
                <br /> <b>{data.BoatInfo.Phone[language]}:</b> {boat.fleetManagerContactInfo.phone} 
                <br /> <b>{data.BoatInfo.Email[language]}:</b> {boat.fleetManagerContactInfo.email} 
                <br /> <br />
                
                <b>{data.BoatInfo.TranspondersNumber[language]}:</b> {Array.isArray(boat.transponders) ? boat.transponders.length : boat.transponders}
                <br/><b>{data.Boat.FishingType[language]}:</b> 
                <br /> <br />
                </p>
                <div className='extra_button'>
                    <Button variant="contained" onClick={sendTranspondersInfo}
                    sx={{ bgcolor: theme.palette.secondary.main, color: theme.palette.info.contrastText,
                    ":hover":{
                        bgcolor: theme.palette.info.main
                    }
                    }}>
                        {data.BoatInfo.Transponders[language]}</Button>
                </div>
                <div className='extra_button'>
                    <Button variant="contained" onClick={sendVoyageInfo}
                    sx={{ bgcolor: theme.palette.primary.dark, color: theme.palette.info.contrastText,
                    ":hover":{
                        color: theme.palette.primary.dark, bgcolor: theme.palette.info.contrastText
                    }
                    }}>
                        {data.BoatInfo.Voyage[language]}</Button>
                </div>
            </div>
            <div className='map'>
                {mapComp}
                <div>
                    <b> {data.BoatInfo.BoatPosition[language]}: {findGradesMinutes(boat.lastKnownPosition?.longitude, false)}, {findGradesMinutes(boat.lastKnownPosition?.latitude, true)} </b>
                    <br/><br/>
                    <b> {transponder !== undefined ? data.BoatInfo.ChosenTransponder[language] + ': ' + transponder.name : ''} </b>
                    {showDetections()}
                    {detectionsToShow.length > 0 ? <div className='container_table'>
                        <div className='type'> <b> {data.BoatInfo.Type[language]} </b> </div> 
                        <div className='time'> <b> {data.BoatInfo.Time[language]} </b> </div>
                        <div className='long'> <b> {data.BoatInfo.Longitude[language]} </b> </div>
                        <div className='lat'> <b> {data.BoatInfo.Latitude[language]} </b> </div>
                        </div> 
                    : <></>}
                    {detectionsToShow.map(detection => (
                        <div className='container_table'>
                            <div className='type'> {data.TransInfo[detection.type][language]} </div> 
                            <div className='time'>  {parseDateTime(detection.detectedAt)} </div>
                            <div className='long'>  {findGradesMinutes(detection.transponderPosition.longitude, false)} </div>
                            <div className='lat'>  {findGradesMinutes(detection.transponderPosition.latitude, true)} </div>
                        </div>
                    ))}
                    {transponder !== undefined ? <MissingButton id={transponder.id}
                     text={transponderMissing ? data.ReportMissing.ReportFound[language] : data.ReportMissing.ReportMissing[language] }
                     functionToApply={fetchAll} current={transponderMissing} />
                    : ''} 
                    
                </div>
            </div>
        </div>
    ) : <div> <Alert severity="error">{data.Alerts.NoBoat[language] }</Alert> </div>
    );
}

export default BoatInfo;

