import {IBuyerDynamicFields, IInputWithLabelProps} from "../../../Types/Registration";
import {PHONE_NO_REGEX, EMAIL_REGEX} from "../../../constants";
import data from './../../../utils/languages.json';

export const dynamicFields: IBuyerDynamicFields[] = [
    {
        mainName: "transponder",
        dynamicFields: ["decodeId", "partNumber", "serialNumber", "gearCode", 'visible'],
    },
    {
        mainName: "transceiver",
        dynamicFields: ["uniqueId", "partNumber", "serialNumber"],
    },
    {
        mainName: "transducerOnBoat",
        dynamicFields: ["uniqueId", "partNumber", "serialNumber"],
    },
    {
        mainName: "charger",
        dynamicFields: ["type", "versionNumber"],
    },
    {
        mainName: "processingUnit",
        dynamicFields: ["uniqueId", "partNumber", "serialNumber"],
    },
    {
        mainName: "map",
        dynamicFields: ["area", "versionNumber"],
    },
    {
        mainName: "gps",
        dynamicFields: ["type", "serialNumber"],
    },
    {
        mainName: "existingGpsOnBoat",
        dynamicFields: ["brand", "versionNumber"],
    },
    {
        mainName: "chartPlotter",
        dynamicFields: ["brand", "versionNumber"],
    },
];

export const FormFields: IInputWithLabelProps[] = [
    {
        name: "buyerName",
        label: data.SellerApp.BuyerInfo.buyerName,
        placeholder: "Buyer Name",
        m: data.SellerApp.BuyerInfo.Messages.buyerName,
        required: true,
    },
    {
        name: "buyerPhone",
        label: data.SellerApp.BuyerInfo.buyerPhone,
        placeholder: "Buyer phone",
        m: data.SellerApp.BuyerInfo.Messages.buyerPhone,
        pattern: PHONE_NO_REGEX,
        required: true,
    },
    {
        name: "buyerEmail",
        label:  data.SellerApp.BuyerInfo.buyerEmail,
        placeholder: "Buyer email",
        m: data.SellerApp.BuyerInfo.Messages.buyerEmail,
        pattern: EMAIL_REGEX,
        required: true,
    }
]


