import React from 'react';
import { MapProps } from './Types'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import './../../CSS/map.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import L from 'leaflet';
import { RootState } from '../../redux/store/store';
import { saveChosenTransponder } from '../../redux/reducers/transponder';
import { useDispatch, useSelector } from 'react-redux';
import data from './../../utils/languages.json'

const Map_ = (map_props: MapProps|undefined) => {

  // const stamenTonerTiles = 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg';
  // const stamenTonerAttr = 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    const CartoDB_Voyager = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png';
    const CartoDB_VoyagerAttr = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
    const OpenSeaMap = 'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png'
    const OpenSeaMapAttr = 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors';
    const Esri_WorldTopoMap = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
    const Esri_WorldTopoMapAtr =  'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
    const Esri_WorldStreetMap = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
    const Esri_WorldStreetMapAttr = 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
    
    const lat_center = useSelector((state: RootState) => state.boat.lat);
    const long_center = useSelector((state: RootState) => state.boat.long);
    const language = useSelector((state: RootState) => state.user.language_name);
    const dispatch = useDispatch();

    const choose_map_container = () => {
        if(map_props?.containerSize === 1){
                return 'map-container';
        }
        else if(map_props?.containerSize === 2){
            return 'map-container2';
        }
    }

    const boatIcon = new L.Icon({
        iconUrl:  require("./b.png"),
        iconRetinaUrl:  require("./b.png"),
        iconSize: [30, 37],
        iconAnchor: [22, 44],
        popupAnchor: [-3, -76],
        shadowUrl: undefined,
        shadowSize: undefined,
        shadowAnchor: undefined,
        className: 'leaflet-venue-icon'
    });

    const transponderIcon = new L.Icon({
        iconUrl:  require("./t.png"),
        iconRetinaUrl:  require("./t.png"),
        iconSize: [25, 27],
        iconAnchor: [22, 34],
        popupAnchor: [-3, -66],
        shadowUrl: undefined,
        shadowSize: undefined,
        shadowAnchor: undefined,
        className: 'leaflet-venue-icon'
    });

    const callDispatch = (marker) => {
        if(marker.type === 'transponder')
            dispatch(saveChosenTransponder(marker.ID))
        else
            dispatch(saveChosenTransponder(undefined))
    }

    function MarkerTypes(marker) {      
        return (
            <Marker eventHandlers={{
                click: () => {
                    callDispatch(marker)
                },}
            }
            position={[marker.latitude, marker.longitude]} 
            icon={marker.type==='boat' ? boatIcon : transponderIcon}>
                <Popup>
                {marker.Name}
                </Popup>
            </Marker>
        )
      }

    const ChangeMapView = ({ coords }) => {
        const map = useMap();
        map.setView(coords, map.getZoom());
        return null;
      }

    return map_props === undefined ? (<p>{data.Alerts.NoMap[language]}</p>) : (
        <MapContainer className={choose_map_container()}
        center={[map_props.center_lat, map_props.center_long]} 
        zoom={map_props.containerSize === 1 ? 9 : 4} maxZoom={17} minZoom={1}>
            <TileLayer
                // attribution={Esri_WorldTopoMapAtr}
                url={Esri_WorldStreetMap}
            />
            <TileLayer
                // attribution={OpenSeaMapAttr}
                url={OpenSeaMap}
            />
            <ChangeMapView coords={choose_map_container() === 'map-container2' ? [lat_center, long_center] : [map_props.center_lat, map_props.center_long]} />
            <MarkerClusterGroup spiderLegPolylineOptions={{
                                    weight: 0,
                                    opacity: 0,
                                }}>
                {map_props.markers.map(marker => (
                        <MarkerTypes {...marker}/>
                ))}
            </MarkerClusterGroup>
        </MapContainer>
    )
}

export default Map_;