import React from 'react';
import { BoatProps } from './Types';
import Map_ from './map';
import Button from '@mui/material/Button';
import './../../CSS/Boats.css';
import './../../CSS/Transponders.css';
import { useNavigate  } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store/store'
import { saveChosenTransponder } from '../../redux/reducers/transponder';
import { saveChosenBoatFromMenu } from '../../redux/reducers/boat';
import data from './../../utils/languages.json';

const Boat_ = (boat: BoatProps) =>  {

  let mapProps;
  const language = useSelector((state: RootState) => state.user.language_name);

  if(boat.boat.lastKnownPosition != null && boat.boat !== undefined){
    mapProps = {
        markers: [{
            latitude: boat.boat.lastKnownPosition?.latitude,
            longitude:  boat.boat.lastKnownPosition?.longitude,
            Name: boat.boat.vesselName,
            ID: boat.boat.id,
            type: 'boat',
        }, ],
        center_lat: boat.boat.lastKnownPosition?.latitude,
        center_long: boat.boat.lastKnownPosition?.longitude,
        containerSize: 1,
    }
  }
  else{
    mapProps = undefined;
  }
   
  let navigate = useNavigate();
  const getBoatInfo = () => {
    dispatch(saveChosenBoatFromMenu({label: boat.boat.vesselName, id: boat.boat.id}));
    dispatch(saveChosenTransponder(undefined))
    navigate("/Boat");
  }
  const dispatch = useAppDispatch();

  return(
    <div>
      <div className='boat_box'>
          <div className='boat_info'>
              <h4> {boat.boat.vesselName} </h4>
              <p> 
                <b>{data.Boat.Owner[language]}:</b> {boat.boat.owner.name}
                <br/><b>{data.Boat.FleetManager[language]}:</b>  {boat.boat.fleetManagerContactInfo.name} 
                <br/><b>{data.Boat.TranspondersNumber[language]}: </b> {boat.boat.transponders}
                <br/><b>{data.Boat.FishingType[language]}: </b> Teine
              </p>
          </div>
          <div className='map_container'>
            {mapProps === undefined ? data.Alerts.UnknownPosition[language] : <Map_ {...mapProps}/>}
          </div>
          <div className='more_info_button'>
            <Button variant="contained" onClick={getBoatInfo}> {data.Boat.moreInfo[language]} </Button>
          </div>
      </div>
    </div>
  );
}

export default Boat_;
