import React, { Component } from "react";
import Header from "./Header";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { changeUserName, clearAll } from '../../redux/reducers/user';
import { setLogedIn, setLogedOut } from '../../redux/reducers/logedIn';
import jwt_decode from "jwt-decode";
import RegisterSeller from "./RegisterSeller";
import Profile from "./Profile";
import Boats from "./Boats";
import BoatInfo from "./BoatInfo";
import Transponders from "./Transponders";
import Voyage from "./Voyage";
import { connect } from "react-redux";
import TransInfo from "./TransInfo";
import { clearAllBoat } from "../../redux/reducers/boatMap";
import { clearAllTransponder } from "../../redux/reducers/transponder";
import { clearRecievedBoatDetails } from "../../redux/reducers/boat";
import Home from "./Home";

const NavRoute = ({path, component: Component, isAuthenticated, roles, token}) => (
  <Route path={path} element={(props) => (
    <div>
      <Header isLoggedIn={isAuthenticated} roles={roles} token={token}/>
      <Component {...props}/>
    </div>
  )}/>
)

type AuthState = {
  roles: string[] | undefined;
  got_token: boolean,
  token: string|undefined,
}

interface authProps extends WithAuth0Props {
  chosenBoatName: string|undefined,
  chosenBoatId: string|undefined,
  redirect: boolean,
  loged: boolean,
  clearAllTransponder: () => void,
  clearAllBoat: () => void,
  clearRecievedBoatDetails: () => void,
  clearAll: () => void,
  changeUserName: (name) => void,
  setLogedIn: () => void,
  setLogedOut: () => void,
}

class Auth extends Component<authProps, AuthState>  {
  routItem: JSX.Element;

  constructor(props: authProps) {
    super(props);
    this.state = {
      roles: undefined,
      got_token: false,
      token: undefined,
    }
    this.routItem = <></>
  }
  
  async componentDidMount(){
    //this.props.clearAll();
    if(!this.props.loged){
      this.clearSaved();
    }
    try{
      let decoded: any = undefined;
      try {
        await this.props.auth0.getAccessTokenSilently({
          audience: `https://osac.eu.auth0.com/api/v2/`,
        }).then((response)=>{
          decoded =  jwt_decode(response);
          if (this.props.auth0.isAuthenticated){
            let roles = decoded['https://app.pingme.no/roles'];
            //If the user doesn't have an owner, fleet manager or vendor role, it will be a fleet manager by default
            if(!roles.includes('owner', 0) && !roles.includes('fleetManager', 0) && !roles.includes('vendor', 0)){
              roles.push('fleetManager');
            }
            this.setState({token: response, roles: roles, got_token: true});
            this.fillMenuItems();
          }
        });
      } catch (e) {
        if(this.props.loged){
          //this.props.auth0.loginWithRedirect();
        }
    }}
    catch{
      this.setState({token: undefined, roles: [], got_token: false});
      this.clearSaved()
    }
  }

  clearSaved = () => {
    this.props.clearAll();
    this.props.clearAllBoat();
    this.props.clearAllTransponder();
    this.props.clearRecievedBoatDetails();
    this.props.setLogedOut();
    this.fillMenuItems();
  }

  
  fillMenuItems = () => {
    if(this.props.auth0.isAuthenticated){
      this.props.changeUserName(this.props.auth0.user?.name);
      if(this.state.roles?.includes('vendor')){
        return (<Router>
          <Routes>
            <Route path={'/'} element={(
              <div>
                <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                token={this.state.token}/>
                <RegisterSeller />
              </div>
            )}/>
            <Route path={'/register'} element={(
              <div>
                <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                token={this.state.token}/>
                <RegisterSeller />
              </div>
            )}/>
            <Route path={'/Home'} element={(
              <div>
                <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                token={this.state.token}/>
                <RegisterSeller />
              </div>
            )}/>
            <Route path={'/Profile'} element={(
              <div>
                <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                token={this.state.token}/>
                <Profile />
              </div>
            )}/>
          </Routes>
        </Router>);
      }
      
      else{
        return(<Router>
                  <Routes>
                    <Route path={'/'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <Boats />
                      </div>
                    )}/>
                    <Route path={'/Home'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <Boats />
                      </div>
                    )}/>
                    <Route path={'/Profile'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <Profile />
                      </div>
                    )}/>
                    <Route path={'/Boats'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <Boats />
                      </div>
                    )}/>
                    <Route path={'/Transponders'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <Transponders />
                      </div>
                    )}/>
                      <Route path={'/Transponder'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <TransInfo />
                      </div>
                    )}/>
                    <Route path={'/Voyage'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <Voyage />
                      </div>
                    )}/>
                    <Route path={'/Boat'} element={(
                      <div>
                        <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                        token={this.state.token}/>
                        <BoatInfo />
                      </div>
                    )}/>
                  </Routes>
                </Router>);
      }
    }
    else{
      return(<Router>
                <Routes>
                  <Route path={'/authorize'} element={(
                    <div>
                      <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                      token={this.state.token}/>
                      <></>
                    </div>
                  )}/>
                  <Route path={'/'} element={(
                    <div>
                      <Header isLoggedIn={this.props.auth0.isAuthenticated} roles={this.state.roles} 
                      token={this.state.token}/>
                      <Home/>
                    </div>
                  )}/>
                </Routes>
              </Router>)
    }
  }


  render() {
    if (this.props.auth0.isLoading || (!this.state.got_token && this.props.auth0.isAuthenticated)){
      return <div> Loading .. </div>
    }
    
    return (
      <div>
        {this.fillMenuItems()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  chosenBoatName: state.boats.boatName,
  chosenBoatId: state.boats.boatId,
  redirect: state.boats.redirect,
  loged: state.loged.Loged,
})

const mapDispatchToProps = (dispatch) => {
  return {
    clearAllTransponder: () => { dispatch(clearAllTransponder()) },
    clearAllBoat: () => { dispatch(clearAllBoat()) },
    clearRecievedBoatDetails: () => { dispatch(clearRecievedBoatDetails()) },
    clearAll: () => { dispatch(clearAll()) },
    changeUserName: (name) => { dispatch(changeUserName(name)) },
    setLogedIn: () => { dispatch(setLogedIn()) },
    setLogedOut: () => { dispatch(setLogedOut()) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(Auth));

