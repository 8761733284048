export const parseDateTime = (dateTime) => {
    const [wholeTime1, other] = dateTime.split('Z')
    const [wholeTime, comma] = wholeTime1.split('.')
    const [date, time] = wholeTime.split('T')
    const newDateTime = date + ' ' + time
    return newDateTime
  }

export const findGradesMinutes = (value, isLatitude) => {
  // Latitude and Longitude converting to grades and minutes 
  const grades = Math.floor(Math.abs(value));
  let minutes = (Math.abs(value) - Math.floor(Math.abs(value)))*60;
  minutes = Number(minutes.toFixed(3));
  const letter = isLatitude ? value >= 0 ? 'N' : 'S' : value >= 0 ? 'E' : 'W';
  return(grades.toString() + '\u00B0 ' + minutes.toString() + ' ' + letter.toString());
}

export let doNotFetch = false;
export const updateDoNotFetch = (calledValue) => {
  doNotFetch = calledValue;
} 
