import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (typeof user === "undefined"){
    return <div> Wrong user! </div>
  }
  if (isAuthenticated){
    return (
        <div>
          <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p>
        </div>
    );
  }
  else return <div> Not Authenticated!</div>
};

export default Profile;