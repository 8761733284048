import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import './../../CSS/main.css';
import { setLogedIn } from "../../redux/reducers/logedIn";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import data from './../../utils/languages.json';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const language = useSelector((state: RootState) => state.user.language_name);
  const dispatch = useDispatch();

  return(
      <Button variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={() => {
        loginWithRedirect();
        dispatch(setLogedIn());
      }}>
        {data.ProfileMenu.login[language]}
      </Button>
  );
};

export default LoginButton;