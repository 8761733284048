import {IInputWithLabelProps} from "../../../Types/Registration";
import {PHONE_NO_REGEX, EMAIL_REGEX} from "../../../constants";
import data from './../../../utils/languages.json';

export const FormFields: IInputWithLabelProps[] = [
    {
        name: "dealerName",
        label: data.SellerApp.DealerInfo.dealerName,
        placeholder: "Dealer name",
        m: data.SellerApp.DealerInfo.Messages.dealerName,
        required: true,
    },
    {
        name: "dealerPhone",
        label: data.SellerApp.DealerInfo.dealerPhone,
        placeholder: "Phone number",
        m: data.SellerApp.DealerInfo.Messages.dealerPhone,
        pattern: PHONE_NO_REGEX,
        required: false,
    },
    {
        name: "dealerEmail",
        label: data.SellerApp.DealerInfo.dealerEmail,
        placeholder: "Email address",
        m: data.SellerApp.DealerInfo.Messages.dealerEmail,
        pattern: EMAIL_REGEX,
        required: true,
    },
];
