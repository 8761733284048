import {useEffect, useState} from "react";
import {
    IBoatInformation,
    IStepsChildren,
} from "../../../Types/Registration";
import {Form, Select} from "antd";
import InputWithLabel from "../InputWithLabel";
import PrevNext from "../PrevNext";
import {FormFields} from "./BoatInformationUtils";
import {useAppSelector, useAppDispatch, RootState} from "../../../redux/store/store";
import moment from 'moment';
import {saveBoatList} from "../../../redux/reducers/boat";
import {Api} from "../../../api"
import { useDispatch, useSelector } from 'react-redux';
import text from '../../../utils/languages.json';


function BoatInformation(props: IStepsChildren<IBoatInformation>) {
    const {current, prev, next, stepsLength, setData, data} = props;

    const [form] = Form.useForm();
    const [options, setOptions] = useState([{label: "a", value: "b", count: 0}]);
    const {expiry, boats} = useAppSelector((state: RootState) => state.boats);
    const dispatch = useAppDispatch();
    const token = useSelector((state: RootState) => state.user.token);
    const language = useSelector((state: RootState) => state.user.language_name);
    let count = -1;
    let insideCount = -1;
    let index = 0;

    useEffect(() => {
        fetchBoats();
    }, []);

    const onFinish = (values: any) => {
        //console.log("Success:", values);
        setData(values);
        next();
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log("Failed:", errorInfo);
    };

    const fetchBoats = async (params?: string) => {
        if (expiry && expiry < moment().format()) {
            let boats = await Api.getBoats(token);
            dispatch(saveBoatList(boats));
        }
        const formatOptions = boats.map((item: any) => {
            count += 1;
            return {
                label: item.vesselName,
                value: item.vesselName,
                key: item.id,
                count: count,
            };
        });
        setOptions(formatOptions);
    };

    const onBoatSelect = (value: any) => {
        const boatDetail: any = boats.filter((item: any) => item.id === value.key)[0];
        if (boatDetail) {
            const {vesselName, vesselRegId, owner, fleetManagerContactInfo} =
                boatDetail;
            const data: IBoatInformation = {
                boatName: vesselName,
                boatRegistrationId: vesselRegId,
                boatOwner: owner?.name,
                companyPhone: owner?.contactInfo?.phone,
                companyEmail: owner?.contactInfo?.email,
                fleetManagerName: fleetManagerContactInfo?.name,
                fleetManagerPhone: fleetManagerContactInfo?.phone,
                fleetManagerEmail: fleetManagerContactInfo?.email,
                boatFlag: "",
                boatNationality: "",
            };
            form.setFieldsValue(data);
        }
    };

    const filterOptions = (input, option) => {
        let score = 0
        for(let i=0; i<input.length; i++){
            if(input.length<=option.label.length){
                if(input[i].toLowerCase()===option.label[i].toLowerCase()){
                    score += 1
                }
            }
        }
        let item = options[index];
        item.count = score;
        options[index] = item;
        setOptions(options);
        index += 1;
        if(index >= options.length)
            index = 0
    }

    return (
        <div>
            <Form
                name="basic"
                form={form}
                initialValues={{remember: true, ...data}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="container">
                    <div className="col-md-6 mb-2">
                        <Form.Item
                            name={"boat"}
                            rules={[{required: true, message: text.SellerApp.Messages.PleaseSelectBoat[language]}]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                optionFilterProp="children"
                                filterOption={(input, option) =>{
                                    if(option!==undefined && input!== undefined)
                                        filterOptions(input, option)
                                    if(option!== undefined && (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)){
                                        return true;
                                    }
                                        return false;
                                }}
                                filterSort={(optionA, optionB) => (optionA.count > optionB.count) ? -1 : 1}
                                onSearch={(params) => fetchBoats(params)}
                                onChange={(newValue) => {
                                    onBoatSelect(newValue);
                                }}
                                options={options}
                                className="w-100"
                                placeholder={text.SellerApp.BoatInfo.SelectBoat[language]}
                            />
                        </Form.Item>
                    </div>
                    <div className="row">
                        {FormFields.map((item) => (
                            <div key={item.name} className="col-md-6">
                                <InputWithLabel {...item} />
                            </div>
                        ))}
                    </div>
                </div>
                <PrevNext current={current} prev={prev} stepsLength={stepsLength}/>
            </Form>
        </div>
    );
}

export default BoatInformation;
