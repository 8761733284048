import {createSlice} from '@reduxjs/toolkit';
import { stat } from 'fs/promises';
import moment from 'moment';
import { detection, Transponder, Transponder_ } from '../../Components/SubComponents/Types';

interface TransponderReducer {
    transponderId: string|undefined,
    chosenTransponderDetails: Transponder_|undefined,
    chosenOneTransponderDetails: Transponder_|undefined,
    changed: boolean,
    oneChanged: boolean,
    chosenTransponderFromMenuName: string,
    chosenTransponderFromMenuId: string,
    recievedTransponder: Transponder_|undefined,
    detections: detection[]|undefined,
    detectionsAdded: boolean,
    expiry: string
}

const initialState: TransponderReducer = {
    transponderId: undefined,
    chosenTransponderDetails: undefined,
    chosenOneTransponderDetails: undefined,
    changed: false,
    oneChanged: false,
    chosenTransponderFromMenuName: '',
    chosenTransponderFromMenuId: '',
    recievedTransponder: undefined,
    detections: undefined,
    detectionsAdded: false,
    expiry: moment().subtract(1, 'days').format()
}

export const transponderSlice = createSlice({
    name: 'transponder',
    initialState,
    reducers: {
        saveChosenTransponder: (state, action) => {
            if(action.payload !== state.transponderId){
                state.changed = true;
                state.transponderId = action.payload;
            }
            else state.changed = false;
            state.expiry = moment().add(1, 'days').format()
        },
        saveChosenTransponderDetails: (state, action) => {
            state.chosenTransponderDetails = action.payload;
        },
        saveChosenOneTransponderDetails: (state, action) => {
            state.chosenOneTransponderDetails = action.payload;
        },
        setChanged: (state, action) => {
            state.changed = action.payload
        },
        setOneChanged: (state, action) => {
            state.oneChanged = action.payload
        },
        saveChosenTransponderFromMenu: (state, action) => {
            state.chosenTransponderFromMenuName = action.payload.label
            state.chosenTransponderFromMenuId = action.payload.id
        },
        saveRecievedTransponderDetails:(state, action) => {
            state.recievedTransponder = action.payload;
        },
        saveDetections:(state, action) => {
            state.detections = action.payload;
            state.detectionsAdded = true;
        },
        ChangeDetectionboolean:(state, action) => {
            state.detectionsAdded = action.payload;
        },
        clearAllTransponder:(state) => {
            state.transponderId = undefined
            state.chosenTransponderDetails = undefined
            state.chosenOneTransponderDetails = undefined
            state.changed = false
            state.oneChanged = false
            state.chosenTransponderFromMenuName = ''
            state.chosenTransponderFromMenuId = ''
            state.recievedTransponder = undefined
            state.detections = undefined
            state.detectionsAdded = false
            state.expiry = moment().subtract(1, 'days').format()
        },
    }
})
export const { saveChosenTransponder, 
               saveChosenTransponderDetails,
               saveChosenOneTransponderDetails,
               setChanged, 
               setOneChanged,
               saveChosenTransponderFromMenu,
               saveRecievedTransponderDetails,
               saveDetections,
               ChangeDetectionboolean,
               clearAllTransponder } = transponderSlice.actions;
export default transponderSlice.reducer;