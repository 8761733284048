import React from 'react';

class Home extends React.Component {

    constructor(props:string) {
        super(props);
        this.state = "Home"
      }

    render() {
      return( 
        <div>
          <h1> Velkommen til PingMe </h1>
        </div>
      );
    }
  }

export default Home;
