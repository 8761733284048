import {Form, Input} from "antd";
import {IInputWithLabelProps} from "../../Types/Registration";
import { RootState } from '../../redux/store/store';
import { useSelector } from 'react-redux';

const InputWithLabel = (props: IInputWithLabelProps) => {
    const {name, label, m, required, pattern, disabled=false} = props;
    const language = useSelector((state: RootState) => state.user.language_name);

    return (
        <>
            <div>{label[language]}</div>
            <Form.Item
                name={name}
                rules={[{required, message: m[language] , pattern,}]}
            >
                <Input disabled={disabled}/>
            </Form.Item>
        </>
    );
};

export default InputWithLabel;
