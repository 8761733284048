import {combineReducers} from "@reduxjs/toolkit";
import IboatReducer from "./boat";
import UserReducer from "./user";
import TransponderReducer from "./transponder";
import boatMapReducer from "./boatMap";
import TransponderMethodsReducer from "./transponders_methods";
import ILogedReducer from "./logedIn";

export const reducers = combineReducers({
    user: UserReducer,
    boats: IboatReducer,
    transponder: TransponderReducer,
    boat: boatMapReducer,
    transpondersMethods: TransponderMethodsReducer,
    loged: ILogedReducer,
});