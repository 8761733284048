import React, { useEffect, useState } from 'react';
import './../../CSS/Transponders.css';
import { Alert, Button, Stack, TextField } from '@mui/material';
import { theme } from '../../utils/themes';
import { RootState } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Api } from '../../api';
import { ChangeDetectionboolean, saveDetections, saveRecievedTransponderDetails } from '../../redux/reducers/transponder';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { doNotFetch, findGradesMinutes, parseDateTime, updateDoNotFetch } from '../../utils/parser';
import data from './../../utils/languages.json';

const TransInfo = () =>  {
    const transponderId = useSelector((state: RootState) => state.transponder.chosenTransponderFromMenuId);
    const transponder = useSelector((state: RootState) => state.transponder.recievedTransponder);
    const recievedDetections = useSelector((state: RootState) => state.transponder.detections);
    const detectionsAdded = useSelector((state: RootState) => state.transponder.detectionsAdded);
    const language = useSelector((state: RootState) => state.user.language_name);
    const [t, setT] = useState(transponderId);
    const [sameTrans, setSameTrans] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [comment, setComment] = useState('');
    const [types, setTypes] = useState([false, false, false, false, false]);
    const [typesString, setTypesString] = useState('');
    const allTypes = ['FOUND','SET','HAUL','SINKING','RISING']
    const [loading, setLoading] = useState(transponder === undefined ? true : false);
        const boatName = useSelector((state: RootState) => state.boats.boatName);
    let boat = useSelector((state: RootState) => state.boats.boatDetails);
    const token = useSelector((state: RootState) => state.user.token);
    const dispatch = useDispatch();


    if(t!=transponderId){
        setT(transponderId)
        setSameTrans(false)
    }

    const fetchTransponder = async () => {
        if(!sameTrans){
            try{
                updateDoNotFetch(true);
                setLoading(true);
                const recievedTrans = await Api.getTransponderDetails(token, transponderId);
                if(recievedTrans.status === 200){
                    updateDoNotFetch(true);
                    setLoading(false);
                    if(recievedTrans.data !== transponder){
                        dispatch(saveRecievedTransponderDetails(recievedTrans.data));
                        updateDoNotFetch(false);
                        setSameTrans(true);
                    }
                    else{
                        setSameTrans(false);
                    }
            }
            else {
                updateDoNotFetch(true);
                setLoading(false)
            }
        }
            catch{
                updateDoNotFetch(true);
                setLoading(false)
            }
      }
    }
      const fetchdetections = async () => {
        checkTypes();
        let recievedData;
        recievedData = await Api.getDetections(token, transponder?.id, startTime, endTime, typesString);
        dispatch(saveDetections(recievedData.data)); 
      }

    useEffect(() => {
        if(!sameTrans && !doNotFetch){
            dispatch(saveDetections(undefined));
            fetchTransponder().catch();
            }
      });

      const getStartTime = (event) => {
        setStartTime(event.target.value+':00Z');
      }
    
      const getEndTime = (event) => {
        setEndTime(event.target.value+':00Z');
      }

    const callDetections = () => {
        dispatch(ChangeDetectionboolean(false));
        if(Date.parse(endTime)<=Date.parse(startTime)){
            setComment(data.Alerts.StartEndDates[language]);
        }
        else{
            fetchdetections();
            setComment('');
        }
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const checkedItems = (name, checked) => {
        let tempTypes = types
        //types = 'FOUND,SET,HAUL,VESSELPOSITION,SINKING,RISING';
        tempTypes[name] = checked;
        setTypes(tempTypes)
        checkTypes();
    }

    const checkTypes = () => {
        let t = '';
        for(let i=0; i<types.length; i++){
            if(types[i]){
                t += allTypes[i]+','
            }
        }
        if(t[t.length-1] === ',')
            t = t.slice(0, t.length-1)
        setTypesString(t)
    }

    if(loading){
        return(<p> {data.Loading.Transponder[language]} </p>)
    }

    return( transponder !== undefined ? (
        <div className='detection'>
            <div className='all'>
                <h1>{transponder?.name}</h1>
                <h4> {data.TransInfo.AddPeriod[language]}: </h4>
                <Stack className="dateTimeBoxesDetection" component="form" noValidate spacing={3}>
                    <TextField
                        id="datetime-local"
                        label={data.TransInfo.Start[language]}
                        type="datetime-local"
                        defaultValue=""
                        sx={{ width: 250 }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={getStartTime}
                    />
                    <TextField
                        id="datetime-local"
                        label={data.TransInfo.To[language]}
                        type="datetime-local"
                        defaultValue=""
                        sx={{ width: 250 }}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        onChange={getEndTime} 
                    />
                </Stack>
                <Box sx={{mt: 5, display: '-moz-initial'}}>
                    <FormLabel component="legend">{data.TransInfo.Choose[language]}</FormLabel>
                    <FormControlLabel
                        control={
                                <Checkbox
                                    {...label}
                                    sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                    }}
                                    name='0'
                                    onChange={(value)=>{checkedItems(Number(value.target.name), value.target.checked)}}
                                />
                            }
                            label={data.TransInfo.FOUND[language]}
                    />
                     <FormControlLabel
                        control={
                                <Checkbox
                                    {...label}
                                    sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                    }}
                                    name='1'
                                    onChange={(value)=>{checkedItems(Number(value.target.name), value.target.checked)}}
                                />
                            }
                            label={data.TransInfo.SET[language]}
                    />
                     <FormControlLabel
                        control={
                                <Checkbox
                                    {...label}
                                    sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                    }}
                                    name='2'
                                    onChange={(value)=>{checkedItems(Number(value.target.name), value.target.checked)}}
                                />
                            }
                            label={data.TransInfo.HAUL[language]}
                    />
                     <FormControlLabel
                        control={
                                <Checkbox
                                    {...label}
                                    sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                    }}
                                    name='3'
                                    onChange={(value)=>{checkedItems(Number(value.target.name), value.target.checked)}}
                                />
                            }
                            label={data.TransInfo.SINKING[language]}
                    />
                    <FormControlLabel
                        control={
                                <Checkbox
                                    {...label}
                                    sx={{
                                    color: pink[800],
                                    '&.Mui-checked': {
                                        color: pink[600],
                                    },
                                    }}
                                    name='4'
                                    onChange={(value)=>{checkedItems(Number(value.target.name), value.target.checked)}}
                                />
                            }
                            label={data.TransInfo.RISING[language]}
                    />
                </Box>
                <p className='comment'> {comment} </p>
                <Button variant="contained" onClick={callDetections}
                    sx={{ bgcolor: theme.palette.primary.dark, color: theme.palette.info.contrastText,
                    ":hover":{
                        color: theme.palette.primary.dark, bgcolor: theme.palette.info.contrastText
                    }
                    }}>
                {data.TransInfo.GetDetections[language]}</Button>
            </div>
            <div>
                {recievedDetections===undefined ? <></> :
                recievedDetections.length===0 && detectionsAdded ? <p className='comment'> {data.Alerts.NoDetections[language]} </p> 
                : <table className='Transponders_table'> 
                    <tr> 
                        <th> {data.TransInfo.Status[language]} </th> 
                        <th> {data.TransInfo.Time[language]} </th>  
                        <th> {data.TransInfo.Temperature[language]} </th>
                        <th> {data.TransInfo.Dyp[language]} </th>
                        <th> {data.TransInfo.Battery[language]} </th>
                        <th> {data.TransInfo.Position[language]} </th>
                        <th> {data.TransInfo.ErrorMaging[language]} </th>
                        <th> {data.TransInfo.BoatPosition[language]}</th>
                    </tr>
                    {recievedDetections.slice(0,1000).map(detection => {
                    return( <tr> 
                                <td> {data.TransInfo[detection.type][language]} </td> 
                                <td> {parseDateTime(detection.detectedAt)} </td>  
                                <td> {detection.temperature.toFixed(2)} </td>
                                <td> {detection.depth.toFixed(2)} </td>
                                <td> {parseFloat(detection.batteryLevel.toFixed(2))} </td>
                                <td> {findGradesMinutes(detection.transponderPosition.latitude, true)}, {findGradesMinutes(detection.transponderPosition.longitude, false)} </td>
                                <td> {parseFloat(detection.errorMargin.toFixed(2))} </td>
                                <td> {findGradesMinutes(detection.vesselPosition.latitude, true)}, {findGradesMinutes(detection.vesselPosition.longitude, false)} </td>
                            </tr>
                )})} 
                </table>}
            </div>
        </div>
    ) : <div> <Alert severity="error">{data.Alerts.NoTransponder[language]}</Alert> </div>
    );
}

export default TransInfo;

