import React from "react";
import {Button} from "antd";
import {IPrevNext} from "../../Types/Registration";
import { RootState } from '../../redux/store/store';
import { useSelector } from 'react-redux';
import data from './../../utils/languages.json';

const PrevNext = (props: IPrevNext) => {
    const {current, stepsLength, prev, done} = props;
    const language = useSelector((state: RootState) => state.user.language_name);
    return (
        <div className="overflow-hidden">
            <div className="steps-action float-end mb-1">
                {current > 0 && (
                    <Button style={{margin: "0 8px"}} onClick={() => prev()}>
                        {data.SellerApp.Buttons.Previous[language]}
                    </Button>
                )}
                {current < stepsLength - 1 && (
                    <Button htmlType="submit" type="primary">
                        {data.SellerApp.Buttons.Next[language]}
                    </Button>
                )}
                {current === stepsLength - 1 && (
                    <Button onClick={() => done ? done() : null} type="primary">
                        {data.SellerApp.Buttons.Done[language]}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PrevNext;
