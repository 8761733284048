import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Api } from '../../api';
import { Data } from './Types';
import { useAppDispatch, RootState } from '../../redux/store/store';
import { useSelector } from 'react-redux';
import { putConfirmed, saveChosenBoatFromMenu } from '../../redux/reducers/boat';
import { useNavigate } from 'react-router-dom';
import { saveChosenTransponder, saveChosenTransponderFromMenu, saveDetections } from '../../redux/reducers/transponder';
import data from './../../utils/languages.json';

type MenuProps = {
  display: String,
  roles: String[],
  token: String | undefined,
}

const Options = [
  'MyBoats',
  'Transponders'
];


const vendorOptions = [
  'Seller Registration',
];

const FireNav = styled(List)<{ component?: React.ElementType }>({
    '& .MuiListItemButton-root': {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  });

const ITEM_HEIGHT = 48;

let names : Array<Data>[] = [];

export default function LongMenu(props: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [forceRerender, setForceRerender] = React.useState(0);
  const [nav, setNav] = React.useState(0);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const language = useSelector((state: RootState) => state.user.language_name);
  let listopenall : boolean[] = [];
  let options : string[] = [];

  const fetchBoatsTransponders = async (token) => {
    try{
      let boats = await Api.getMyBoats(token)
      if(boats.status === 200){
          names[0] = [];
          boats.data.map((item) => {
            names[0].push({label: item.vesselName, id: item.id});
          })
          names[0].push({label: data.SmallMenu.all[language], id:''})
        
          names[1] = [];
          if(boats.data.length === 0){
            return
          }
          boats.data.forEach(async function (value) {
            const recievedBoat = await Api.getBoatDetails(token, value.id);
            if(recievedBoat.status === 200){
              recievedBoat.data.transponders.map((transponder) => {
                names[1].push({label: transponder.name, id: transponder.id});
              })
            }
          });
      }
    }
    catch{
      return
    }
  }
  
  React.useEffect(() => {
    dispatch(putConfirmed(false))
    //fetchBoats(props.token, props.roles);
    if(props.roles.includes('owner') || props.roles.includes('fleetManager')){
      fetchBoatsTransponders(props.token);
      dispatch(saveDetections(undefined));
    }
  }, []);

  const printData = (index) => {
    return true;
  }

  const saveChosenBoatOrTransponder = (index, item) => {
    dispatch(saveDetections(undefined));
    if(index == 0 && (props.roles.includes('owner') || props.roles.includes('fleetManager'))){
      if(item.label === data.SmallMenu.all.English || item.label === data.SmallMenu.all.Norsk){
        navigate('/Boats')
      }
      else{
         // item have label(name) and id
        dispatch(saveChosenBoatFromMenu(item));
        dispatch(saveChosenTransponder(undefined))
        navigate("/Boat");
      }
    }
    else if(index == 1 && (props.roles.includes('owner') || props.roles.includes('fleetManager'))){
      dispatch(saveChosenTransponderFromMenu(item));
      navigate("/Transponder");
    }
  }

  if(props.roles.includes('vendor')){
    options = vendorOptions;
    for (let i = 0; i < vendorOptions.length; i++) {
      listopenall.push(false);
      names.push([]);
    }
  }

  else if(props.roles.includes('owner') || props.roles.includes('fleetManager')){
    options = Options;
    for (let i = 0; i < Options.length; i++) {
      listopenall.push(false);
    }
  }

  else{

  }
  let tempList = listopenall;
  const [boatLisstOpen, setBoatLisstOpen] = React.useState(listopenall);
  const [LisstOpen, setLisstOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if(props.display !== 'none' && nav === 0){
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          sx={{color: "white"}}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map((option, index) => {
            return(
              <Box >
              <ThemeProvider
                theme={createTheme({
                  components: {
                    MuiListItemButton: {
                      defaultProps: {
                        disableTouchRipple: true,
                      },
                    },
                  },
                  palette: {
                    mode: 'dark',
                    primary: { main: '#030F37' },
                    background: { paper: '#020c2c' },
                  },
                })}
              >
                <Paper elevation={0} sx={{ maxWidth: 256 }}>
                  <FireNav component="nav" disablePadding>
                    <Box
                      sx={{
                        bgcolor: LisstOpen[index] ? 'rgba(9, 0, 70, 0.92)' : null,
                        pb: LisstOpen[index] ? 2 : 2,
                      }}
                    >
                      <ListItemButton
                        alignItems="flex-start"
                        onClick={() => {
                          tempList[index] = !boatLisstOpen[index]
                          setBoatLisstOpen(tempList)
                      }}
                        sx={{
                          px: 3,
                          pt: 2.5,
                          pb: LisstOpen[index] ? 2.5 : 2.5,
                          '&:hover, &:focus': { '& svg': { opacity: LisstOpen[index] ? 1 : 1 } },
                        }}
                      >
                        <ListItemText
                          primary= {props.roles.includes('vendor') ? option : data.SmallMenu[option][language]}
                          primaryTypographyProps={{
                            fontSize: 15,
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                          }}
                          sx={{ my: 0 }}
                        />
                        
                      {props.roles.includes('vendor') ? (<></>) : (<KeyboardArrowDown
                          sx={{
                            mr: -3.5,
                            opacity: 0,
                            transform: boatLisstOpen[index] ? 'rotate(-180deg)' : 'rotate(0)',
                            transition: '0.2s',
                          }}
                        />)}
                      </ListItemButton>
                      
                      {props.roles.includes('vendor') ? (<></>) : (<>
                        {boatLisstOpen[index] && printData(index) && names[index] !== undefined &&
                        names[index].map((item, smallerIndex) => { 
                          return(
                          <ListItemButton
                            key={item.label}
                            onClick={() => {saveChosenBoatOrTransponder(index, item)}}
                            sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
                          >
                            <ListItemText
                              primary={index === 0 && smallerIndex === names[index].length-1 ? data.SmallMenu.all[language] : item.label}
                              primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                            />
                          </ListItemButton>
                        )})}
                      </>)}
                    </Box>
                  </FireNav>
                </Paper>
              </ThemeProvider>
            </Box>
          )})}
        </Menu>
      </div>
    );
  }
  return(
    <></>
  )
}