import {IInputWithLabelProps} from "../../../Types/Registration";
import {EMAIL_REGEX, PHONE_NO_REGEX} from "../../../constants";
import data from './../../../utils/languages.json';

export const FormFields: IInputWithLabelProps[] = [
    {
        name: "boatName",
        label: data.SellerApp.BoatInfo.boatName,
        placeholder: "Email address",
        m: data.SellerApp.BoatInfo.Messages.boatName,
        required: false,
        disabled: true
    },
    {
        name: "boatRegistrationId",
        label: data.SellerApp.BoatInfo.boatRegistrationId,
        placeholder: "Boat registration id",
        m: data.SellerApp.BoatInfo.Messages.boatRegistrationId,
        required: false,
        disabled: true
    },
    {
        name: "boatFlag",
        label: data.SellerApp.BoatInfo.boatFlag,
        placeholder: "Boat Flag (IMO)",
        m: data.SellerApp.BoatInfo.Messages.boatFlag,
        required: false,
        disabled: true
    },
    {
        name: "boatNationality",
        label: data.SellerApp.BoatInfo.boatNationality,
        placeholder: "Boat Nationality",
        m: data.SellerApp.BoatInfo.Messages.boatNationality,
        required: false,
        disabled: true
    },
    {
        name: "boatOwner",
        label: data.SellerApp.BoatInfo.boatOwner,
        placeholder: "Boat Owner",
        m: data.SellerApp.BoatInfo.Messages.boatOwner,
        required: false,
    },
    {
        name: "companyPhone",
        label: data.SellerApp.BoatInfo.companyPhone,
        placeholder: "Boat Owner/Company Phone Number",
        m: data.SellerApp.BoatInfo.Messages.companyPhone,
        pattern: PHONE_NO_REGEX,
        required: true,
    },
    {
        name: "companyEmail",
        label: data.SellerApp.BoatInfo.companyEmail,
        placeholder: "Boat Owner",
        m: data.SellerApp.BoatInfo.Messages.companyEmail,
        pattern: EMAIL_REGEX,
        required: true,
    },
    {
        name: "fleetManagerName",
        label: data.SellerApp.BoatInfo.fleetManagerName,
        placeholder: "Fleet Manager's Name",
        m: data.SellerApp.BoatInfo.Messages.fleetManagerName,
        required: true,
    },
    {
        name: "fleetManagerPhone",
        label: data.SellerApp.BoatInfo.fleetManagerPhone,
        placeholder: "Fleet Manager's Phone Number",
        m: data.SellerApp.BoatInfo.Messages.fleetManagerPhone,
        pattern: PHONE_NO_REGEX,
        required: true,
    },
    {
        name: "fleetManagerEmail",
        label: data.SellerApp.BoatInfo.fleetManagerEmail,
        placeholder: "Email address",
        m: data.SellerApp.BoatInfo.Messages.fleetManagerEmail,
        pattern: EMAIL_REGEX,
        required: true,
    }
];

export const boatListMock = [
    {
        id: "7a48306a-d315-4aa4-97d0-eedb1e645fad",
        vesselName: "ANKA",
        vesselRegId: "VT0018P",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "bce2038d-e883-4f53-8ad6-5b3083876b67",
            name: "GEIR LAURITZEN ENK",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
    {
        id: "90561814-a56e-4693-8898-a0ac5a057121",
        vesselName: "ALBATROSS",
        vesselRegId: "VT0030SK",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "d37749a3-1a4d-44e4-b24c-1c6b2619f1de",
            name: "ABRAHAMSENFISK AS",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
    {
        id: "be17e512-ab26-4ff1-8517-a29b780917ba",
        vesselName: "BRENTHOLMEN",
        vesselRegId: "VT0031S",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "52e3ebf0-3520-4759-98dd-4a862c5602c7",
            name: "VIDAR MIKKELSEN",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
    {
        id: "e6fea6d7-23e1-4edb-baa1-a9a1e6ff9a3f",
        vesselName: "RISØY",
        vesselRegId: "VT0011F",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "024448aa-121f-4444-b508-5982ec4577ad",
            name: "KLUNGERBO THOR EGIL",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
    {
        id: "49f7005f-a5c0-412a-b626-60126d82606d",
        vesselName: "KVEITA",
        vesselRegId: "VT0010S",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "096d28b4-c844-4793-a89f-3fe548473ddd",
            name: "ANDERS IVERSEN",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
    {
        id: "d62f2bf3-8c9a-4008-976b-ac235fcc81e9",
        vesselName: "SHORES 1",
        vesselRegId: "VT0010F",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "a3cf0e11-eed2-4860-8241-b66ae4883809",
            name: "NORWEGIAN SHORES AS",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
    {
        id: "88f03865-f961-4724-8d18-cc911160fce7",
        vesselName: "LINNEA",
        vesselRegId: "VT0077F",
        fleetManagerContactInfo: {
            phone: null,
            email: null,
        },
        owner: {
            id: "b2d218f2-f426-4b29-a002-49154502d85d",
            name: "RONNY NORDSKOG",
            contactInfo: {
                phone: null,
                email: null,
            },
        },
    },
];

