import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import { position } from '../../Components/SubComponents/Types';

interface boatMapReducer {
    boatName: string|undefined,
    boatId: string|undefined,
    lat: number,
    long: number,
    boatPos: position|undefined,
    expiry: string
}

const initialState: boatMapReducer = {
    boatName: undefined,
    boatId: undefined,
    boatPos: undefined,
    lat: 0,
    long: 0,
    expiry: moment().subtract(1, 'days').format()
}

export const boatMapSlice = createSlice({
    name: 'boatMap',
    initialState,
    reducers: {
        saveBoatName: (state, action) => {
            state.boatName = action.payload
            state.expiry = moment().add(1, 'days').format()
        },
        saveBoatId: (state, action) => {
            state.boatId = action.payload
            state.expiry = moment().add(1, 'days').format()
        },
        saveBoatPos: (state, action) => {
            state.boatPos = action.payload
            state.expiry = moment().add(1, 'days').format()
        },
        saveMapCenter: (state, action) => {
            state.lat = action.payload.lat
            state.long = action.payload.long
            state.expiry = moment().add(1, 'days').format()
        },
        clearAllBoat: (state) => {
            state.boatName = undefined
            state.boatId = undefined
            state.boatPos = undefined
            state.lat = 0
            state.long = 0
            state.expiry = moment().subtract(1, 'days').format()
        },
    }
})
export const { saveBoatName, saveBoatId, saveBoatPos, saveMapCenter, clearAllBoat } = boatMapSlice.actions;
export default boatMapSlice.reducer;