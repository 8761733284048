import React from 'react';
import { Transponder_ } from './../SubComponents/Types';
import './../../CSS/Transponders.css';
import './../../CSS/main.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Transponder from '../SubComponents/Transponder';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import data from './../../utils/languages.json';

const Transponders = () =>  {

  const state = useLocation();
  let transponders:Transponder_[] | undefined; 
  let boatName: string | undefined;
  const language = useSelector((state: RootState) => state.user.language_name);

  const token = useSelector((state: RootState) => state.user.token);
  
  Object.entries(state).map(([key, value]) => {
      if(key === "state"){
          if(value){
              transponders = value.transponders;
              boatName = value.boatName;
          }
      }
    })
  if(transponders === undefined){
    return(<div> <Alert severity="error">{data.Alerts.NoTransponder[language]}</Alert> </div>);
  }


  return(
      <div className='transponders_box'>
        <h2> {data.Transponders.TranspondersToBoat[language]}: {boatName} </h2>
        <table className='Transponders_table'>
          <tr>
            <th> {data.Transponder.Name[language]} </th>
            <th> {data.Transponder.Visiblity[language]} </th>
            <th> {data.Transponder.HardwareId[language]} </th>
            <th> {data.Transponder.MissingAt[language]} </th>
            <th> {data.Transponder.lastStatus[language]} </th>
            <th> {data.Transponder.LastDetectionTime[language]} </th>
            <th>  </th>
          </tr>
          {transponders.map(transponder => {
          return(
            <Transponder transponder={transponder} token={token}/>
          )})}
        </table>
      </div>
  );
}

export default Transponders;