import React, { useEffect, useState } from 'react';
import { Boat__ } from './../SubComponents/Types';
import './../../CSS/Boats.css';
import './../../CSS/BoatInfo.css';
import { Button } from '@mui/material';
import { theme } from '../../utils/themes';
import { RootState } from '../../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';

let boat:Boat__ | undefined; 

const MissingButton = (props:{id: string, current: boolean, text: string, functionToApply: ()=>void}) =>  {
    const id = props.id;
    const currentState = props.current;
    const [transponderMissing, setTransponderMissing] = useState(props.current);
    const token = useSelector((state: RootState) => state.user.token);
    const dispatch = useDispatch();

    const sendMissing = (missing: boolean, id) => {
        const infoToSend = {
            "missing": missing
            }
        let axios = require("axios").default;
        axios.patch("https://api.pingme.no/app/transponders/"+id, infoToSend, {headers: {'authorization':  `Bearer ${token}`}})
        .then( () => {
        //console.log('sent');
        setTransponderMissing(missing);
        props.functionToApply();

        }).catch(function () {
        //console.log('error')
        });
    }

    useEffect(() => {
        });

    const reportAsMissing = (id) => {
        if(transponderMissing){
            sendMissing(false, id);
        }
        else {
            sendMissing(true, id);
        }
    }

    return(
        <div className='container_boat'>
            <Button variant="contained" onClick={() => {reportAsMissing(id)}}
                        sx={{ bgcolor: currentState ? 'green' : theme.palette.secondary.main, color: theme.palette.info.contrastText,
                                mt: 1, fontSize: "80%",
                                maxWidth: '80%', maxHeight: '100%', minWidth: '60%', minHeight: '60%',
                        ":hover":{
                            bgcolor: currentState ? theme.palette.info.dark : theme.palette.info.main
                        }
                        }}> 
            {props.text} </Button>
        </div>
    );
}

export default MissingButton;

