import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import { Transponder } from '../../Components/SubComponents/Types';

interface TransponderMethodsReducer {
    temp: undefined,
    expiry: string
}

const initialState: TransponderMethodsReducer = {
    temp: undefined,
    expiry: moment().subtract(1, 'days').format()
}

export const transponderMSlice = createSlice({
    name: 'transponder',
    initialState,
    reducers: {
        reportAsMissing: (state, action) => {
            state.temp = undefined
            state.expiry = moment().subtract(1, 'days').format()
        },
        changeVisibility: (state, action) => {
            state.temp = undefined
            state.expiry = moment().subtract(1, 'days').format()
        }
    }
})
export const { reportAsMissing, changeVisibility } = transponderMSlice.actions;
export default transponderMSlice.reducer;