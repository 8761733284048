import React, { Component } from 'react';
import './../../CSS/main.css';
import LoginButton from './Login';
import LogoutButton from './Logout';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { theme } from '../../utils/themes';
import './../../CSS/main.css';
import { changeLanguage, saveToken, saveUserRole } from '../../redux/reducers/user';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import LongMenu from './../SubComponents/menu'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import data from './../../utils/languages.json';

const FireNav = styled(List)<{ component?: React.ElementType }>({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

type HeaderProps = {
  isLoggedIn: boolean,
  roles: string[]|undefined,
  token: string|undefined,
  role: string[]|undefined,
  chosenBoatName: string|undefined,
  chosenBoatId: string|undefined,
  redirect: boolean,
  language: number,
  userName: string,
  language_name: string,
  saveUserRole: (role:string[]|undefined) => void,
  saveToken: (token:string|undefined) => void,
  changeLanguage: (language:number) => void,
}

type HeaderState = {
  isLoggedIn: boolean,
  roles: string[]|undefined,
  hamburgerOpen: boolean,
  menuOpen: boolean,
  win_size: Array<number>,
  boatLisstOpen: boolean,
  chosenBoatName: string|undefined,
  chosenBoatId: string|undefined,
  anchorEl: null | HTMLElement,
  menuIsOpen: boolean,
}

class Header extends Component<HeaderProps, HeaderState> {
  menuItems: JSX.Element;
  limitScreenSize: number;
  miniList: JSX.Element;
  language: JSX.Element;
  
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      isLoggedIn: this.props.isLoggedIn,
      roles: this.props.roles,
      hamburgerOpen: false,
      menuOpen: false,
      win_size: [window.innerWidth, window.innerHeight],
      boatLisstOpen: false,
      chosenBoatName: this.props.chosenBoatName,
      chosenBoatId: this.props.chosenBoatId,
      anchorEl : null,
      menuIsOpen: false,
    }

    this.props.saveUserRole(this.props.roles);
    this.props.saveToken(this.props.token);
    
    this.menuItems = <></>;
    this.miniList = <></>;
    this.language = <></>;

    this.limitScreenSize = 1100;

    this.fillMenuItems();
    const updateSize = () => {
      const s = {win_size: [window.innerWidth, window.innerHeight]};
      this.setState(s);
    }
    window.addEventListener('resize', updateSize);
  }

  toggleHamburger = (check: boolean) =>{
    if(check){
      const s = {
        hamburgerOpen: !this.state.hamburgerOpen,
      };
      this.setState(s)
      return true;
    }
    return false;
  }

  class_name_choose = () => {
    //These class names are used in css design
    if(!this.state.isLoggedIn)
      return 'menu_element_none';
    if (this.state.win_size[0] <= this.limitScreenSize){
      return 'hamburger';
    }
    else{
      return 'menu_elemnts';
    }
  }

  display = (check: boolean) => {
    if (check){
      return '';
    }
    else{
      return 'menu_element_none';
    }
  }

  choose_item_class = () => {
    return 'menu_element';
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({anchorEl: event.currentTarget, menuIsOpen:  Boolean(event.currentTarget)});
  };

  handleClose = () => {
    this.setState({anchorEl: null, menuIsOpen:  Boolean(false)});
  };

  fillMenuItems = () => {
    if(this.state.isLoggedIn){
      this.miniList = <>
          <IconButton
            onClick={this.handleClick}
            size="small"
            sx={{ ml: 2, width: 10 }}
            aria-controls={this.state.menuIsOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.menuIsOpen ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{this.props.userName[0].toUpperCase()}</Avatar>
          </IconButton>
          <Menu
            anchorEl={this.state.anchorEl}
            id="account-menu"
            open={this.state.menuIsOpen}
            onClose={this.handleClose}
            onClick={this.handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
          <a href="/Profile" className='mini_menu_element'><MenuItem>
            <Avatar /> {data.ProfileMenu.Profile[this.props.language_name]}
          </MenuItem></a>
          <Divider />
          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {data.ProfileMenu.Settings[this.props.language_name]}
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <LogoutButton />
          </MenuItem>
        </Menu> 
        </>

      this.menuItems = 
        <div>
          {this.miniList}
          <LongMenu roles={ this.state.roles !== undefined ? this.state.roles : [''] }
          display={''} token={this.props.token} />  
        </div>

      this.language = 
        <div>
          <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button variant="outlined" color="secondary" size="small"
                    endIcon={<KeyboardArrowDownIcon />}
                    {...bindTrigger(popupState)}>
                    {this.props.language === 1 ? 'EN' : 'NO'}
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={()=>{this.props.changeLanguage(1)}}>EN</MenuItem>
                    <MenuItem onClick={()=>{this.props.changeLanguage(2)}}>NO</MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
        </div>
    }
    else{
      this.menuItems = <div className={this.choose_item_class()}> <LoginButton /> </div>
    }
  }

  render(): React.ReactNode {
    return (
      <div>
        <Toolbar sx={{ flexWrap: 'wrap', bgcolor: theme.palette.primary.dark}}>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} className='pingme_word'>
            PingMe
          </Typography>
          {this.language}
          <div className='headerMenus'>
            {this.fillMenuItems()}
            {this.menuItems}
          </div>
        </Toolbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  role: state.role,
  chosenBoatName: state.boats.boatName,
  chosenBoatId: state.boats.boatId,
  redirect: state.boats.redirect,
  language: state.user.language,
  language_name: state.user.language_name,
  userName: state.user.userName,
}
)

const mapDispatchToProps = (dispatch) => {
  return {
    saveUserRole: (roles) => { dispatch(saveUserRole(roles)) },
    saveToken: (token) => { dispatch(saveToken(token)) },
    changeLanguage: (language) => { dispatch(changeLanguage(language)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
