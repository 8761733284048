import React from 'react';
import Boat_ from '../SubComponents/Boat';
import { BoatsState, BoatsProps } from './../SubComponents/Types';
import './../../CSS/Transponders.css';
import './../../CSS/main.css';
import { connect } from 'react-redux';
import data from './../../utils/languages.json';


class Boats extends React.Component<BoatsProps, BoatsState>  {
    constructor(props) {
        super(props);
        this.state = {
          all_boats: [],
        }
      }

    componentDidMount(){
      var axios = require("axios").default;
      var options = {
        method: 'GET',
        url: "https://api.pingme.no/app/vessels",
        headers: {authorization:  `Bearer ${this.props.token}`}
      };
  
      axios.request(options).then( (response) => {
        this.setState({all_boats: response.data})
  
      }).catch(function (error) {
        //console.log(error);
      });

    }

    render() {
      return(
        <div>
          <h2 className='title'> {data.MyBoats.MyBoats[this.props.language_name]} </h2>
          <div>
          {this.state.all_boats.map(boat => (
              <Boat_ boat={boat} />
            ))}
          </div>
        </div>
      );
    }
  }

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    language_name: state.user.language_name,
  }
}

export default connect(mapStateToProps,)(Boats);
