import { Form, Input, Button, Switch, Space } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { IBuyerDynamicFields } from "../../Types/Registration";
import { camelCaseToTitleCase } from "../../utils";
import { RootState } from '../../redux/store/store';
import { useSelector } from 'react-redux';
import data from './../../utils/languages.json';

function DynamicForm(props: IBuyerDynamicFields) {
    const { mainName, dynamicFields } = props;
    const language = useSelector((state: RootState) => state.user.language_name);
    return (
        <div>
            <Form.List name={mainName}>
                {(fields, { add, remove }) => (
                    <>
                        <Form.Item>
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-3">{data.SellerApp.BuyerInfo[mainName][mainName][language]}</div>
                                    <div className="col-sm-3">
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<PlusOutlined />}
                                            onClick={() => add()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form.Item>
                        {fields.map(({ key, name, ...restField }) => {
                        return (
                            <Space
                                key={key}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                            >
                                {dynamicFields.map((item, index) => (
                                    <Form.Item
                                        key={index}
                                        {...restField}
                                        name={[name, item]}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${data.SellerApp.Messages.Missing[language]} ${camelCaseToTitleCase(item)} ${mainName}`,
                                            },
                                        ]}
                                    >
                                        {item==='visible'? <label className="font_grey">{data.SellerApp.BuyerInfo.transponder.visible[language]}<select className="Selects">
                                                                <option value="" className="font_black">{data.SellerApp.BuyerInfo.transponder.Choose[language]}</option>
                                                                <option value="true" className="font_black">{data.SellerApp.BuyerInfo.transponder.true[language]}</option>
                                                                <option value="false" className="font_black">{data.SellerApp.BuyerInfo.transponder.false[language]}</option>
                                                            </select></label>:
                                        item === 'gearCode'? <label className="font_grey">{data.SellerApp.BuyerInfo.transponder.gearCode[language]}<select className="Selects">
                                                                <option value="">{data.SellerApp.BuyerInfo.transponder.Choose[language]}</option>
                                                                <option value="pot" className="font_black">{data.SellerApp.BuyerInfo.transponder.pot[language]}</option>
                                                                <option value="line" className="font_black">{data.SellerApp.BuyerInfo.transponder.line[language]}</option>
                                                                <option value="gill net" className="font_black">{data.SellerApp.BuyerInfo.transponder.gillnet[language]}</option>
                                                                <option value="NN" className="font_black">{data.SellerApp.BuyerInfo.transponder.nn[language]}</option>
                                                            </select></label>:
                                        item === 'brand' && mainName === 'chartPlotter'? <label className="font_grey">{data.SellerApp.BuyerInfo.chartPlotter.brand[language]}<select className="Selects">
                                                            <option value="">{data.SellerApp.BuyerInfo.transponder.Choose[language]}</option>
                                                            <option value="SailorsMate" className="font_black">SailorsMate</option>
                                                            <option value="OSAC" className="font_black">OSAC</option>
                                                           </select></label>:
                                        <Input placeholder={data.SellerApp.BuyerInfo[mainName][item][language]} />}
                                    </Form.Item>
                                ))}
                                <MinusOutlined className="ms-2"   onClick={() => remove(name)} />
                            </Space>
                        )})}
                    </>
                )}
            </Form.List>
        </div>
    );
}

export default DynamicForm;