import {axios} from "./config";
import {boatListMock} from "../Components/SellerRegistration/BoatInformation/BoatInformationUtils"

class API {

    async getBoats(token){
      var axios = require("axios").default;
      var options = {
        method: 'GET',
        url: "https://api.pingme.no/sales/vessels",
        headers: {authorization:  `Bearer ${token}`}
      };
      console.log(token)
  
      return(axios.request(options))
        // return boatListMock
    }

    async getMyBoats(token){
      var axios = require("axios").default;
      var options = {
        method: 'GET',
        url: "https://api.pingme.no/app/vessels",
        headers: {authorization:  `Bearer ${token}`}
      };
      return(axios.request(options))
    }

    async getBoatDetails(token, id){
      var axios = require("axios").default;
      var options = {
        method: 'GET',
        url: `https://api.pingme.no/app/vessels/${id}`,
        headers: {authorization:  `Bearer ${token}`}
      };
      return(axios.request(options))
    }

    async getTransponderDetails(token, id){
      var axios = require("axios").default;
      var options = {
        method: 'GET',
        url: `https://api.pingme.no/app/transponders/${id}`,
        headers: {authorization:  `Bearer ${token}`}
      };
      return(axios.request(options))
    }

    async getDetections(token, id, time1='', time2='', types=''){
      const axios = require("axios").default;
      let options;
      if (time1 !== '' && time2 !== '' && types !== '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?from=${time1}&to=${time2}&detectionTypes=${types}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 !== '' && time2 !== '' && types === '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?from=${time1}&to=${time2}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 !== '' && time2 === '' && types === '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?from=${time1}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 === '' && time2 === '' && types !== '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?detectionTypes=${types}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 !== '' && time2 === '' && types !== '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?from=${time1}&detectionTypes=${types}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 === '' && time2 !== '' && types === '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?to=${time2}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 === '' && time2 === '' && types === '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      else if (time1 === '' && time2 !== '' && types !== '')
        options = {
          method: 'GET',
          url: `https://api.pingme.no/app/detections/${id}?to=${time2}&detectionTypes=${types}`,
          headers: {authorization:  `Bearer ${token}`}
        };
      return(axios.request(options))
    }
}
export const Api = new API();