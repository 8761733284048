import {createSlice} from '@reduxjs/toolkit';

interface ILogedReducer {
    Loged: boolean,
}

const initialState: ILogedReducer = {
    Loged: false,
}

export const logedSlice = createSlice({
    name: 'loged',
    initialState,
    reducers: {
        setLogedIn: (state) => {
            state.Loged = true;
        },
        setLogedOut: (state) => {
            state.Loged = false;
        },
    }
})
export const {setLogedIn, setLogedOut} = logedSlice.actions;
export default logedSlice.reducer;