import React, { Component } from "react";
import { MapContainer, TileLayer, Tooltip, Polyline, useMap } from "react-leaflet";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import { connect } from "react-redux";
import L, { LatLngExpression } from "leaflet";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Alert, Button } from "@mui/material";
import { theme } from "../../utils/themes";
import './../../CSS/Voyage.css';
import { detection, position } from "../SubComponents/Types";
import { Api } from "../../api";
import { findGradesMinutes, parseDateTime } from "../../utils/parser";
import data from './../../utils/languages.json';

type VoyageProps = {
    boatName: string|undefined,
    boatId: string|undefined,
    boatPos: position|undefined,
    token: string,
    language: string,
  }
  
  type VoyageState = {
    latlng: { lat: number; lng: number; },
    speed: number,
    speedAlert: string,
    start_pos: number[],
    detections: detection[],
    startTime: string,
    endTime: string,
    positions3: LatLngExpression[][]|number[][],
    positions2: number[][],
    polyline: any,
    change: boolean,
    empty: boolean,
  }

class Voyage extends Component<VoyageProps, VoyageState> {
    count: number;
    Esri_WorldStreetMap = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
    Esri_WorldStreetMapAttr = 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
    boatName: string | undefined;
    positions: (string | number)[][];
    stop: boolean;
    x: number[][];

    constructor(props) {
        super(props);
        this.state = {
            latlng: this.gen_position(),
            speed: 2000,
            start_pos: this.props.boatPos === undefined ? [63.4412, 10.476337] : [this.props.boatPos.latitude, this.props.boatPos.longitude],
            detections: [],
            startTime: '',
            endTime: '',
            positions3: [[0, 0]],
            positions2: [[0, 0]],
            polyline: <></>,
            change: false,
            empty: true,
            speedAlert: '',
        }
        const today = new Date();
        const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        const month = lastWeek.getMonth() + 1;
        let monthString = month.toString();
        if (month < 10)
          monthString = '0' + monthString;
        const lastWeekDate = lastWeek.getFullYear()+'-'+monthString+'-'+lastWeek.getDate()+'T'+lastWeek.toString().split(' ')[4]+'Z'
        this.setState({startTime: lastWeekDate})

        this.count = 0;
        this.positions = [this.props.boatPos === undefined ? ['', 63.4412, 10.476337] : ['', this.props.boatPos.latitude, this.props.boatPos.longitude]]
        this.x = this.positions.map(function() { return [0, 0]; })
        this.setState({positions2 : this.x});
        this.setState({start_pos: this.state.positions2[0], positions3: this.state.positions2, polyline: <Polyline positions={this.state.positions3 as [number, number][]} color="blue" />});
        this.boatName = this.props.boatName;
        this.stop = false;
        this.fetchdetections();
      }

  componentDidMount() {
    this.fetchdetections();
  }

  fetchdetections = async () => {
    let recievedData;
    this.setState({change: true})
    this.count = 0;
    recievedData = await Api.getDetections(this.props.token, this.props.boatId, this.state.startTime, this.state.endTime);
    this.setState({detections: recievedData.data})
    this.positions = []
    this.state.detections.reverse().forEach((element, index) => {
    this.positions.push([element.detectedAt, element.vesselPosition.latitude, element.vesselPosition.longitude])
    });
    if(this.positions.length === 0)
      this.setState({empty: true})
    else
      this.setState({empty: false})
    this.setState({positions2: this.positions.map(function(value) { 
      if (typeof value[1] === "number" && typeof value[2] === "number") {
        return [value[1], value[2]]; 
      }
      else{
        return [0, 0];
      }
    })});
    this.setState({positions3: this.state.positions2,})
    if(this.state.empty)
    this.setState({start_pos: [64.991749, 9.312813], polyline: <Polyline positions={this.state.positions3 as [number, number][]} color="blue" />,});
    else
      this.setState({start_pos: this.state.positions2[0], latlng: {lat:this.state.positions2[0][0], lng:this.state.positions2[0][1] }, polyline: <Polyline positions={this.state.positions3 as [number, number][]} color="blue" />,});
  }

  repeat = () => {
    if( !this.stop ){
      setTimeout(() => {
        // updates position every 5 sec
        this.setState({ latlng: this.gen_position() }, this.repeat);
      }, this.state.speed);
    }
  };

  gen_position() {
    if(Number.isNaN(this.count) || this.positions === undefined){
        return {
          lat:this.props.boatPos === undefined ? this.state.start_pos[0] : this.props.boatPos.latitude, 
          lng:this.props.boatPos === undefined ? this.state.start_pos[1] : this.props.boatPos.longitude ,
        };
    }
    else{
        this.count = this.count + 1;
        if(this.state.positions2 !== undefined && this.state.positions2[this.count] !== undefined){
          return {
            lat: this.state.positions2[this.count][0],
            lng: this.state.positions2[this.count][1]
          };
        }
        else{
          return {
            lat: this.state.start_pos[0],
            lng: this.state.start_pos[1],
          };
        }
    }
  }

  lineIcon = new L.Icon({
    iconUrl:  require("./../SubComponents/l.png"),
    iconRetinaUrl:  require("./../SubComponents/l.png"),
    iconSize: [30, 37],
    iconAnchor: [22, 44],
    popupAnchor: [-3, -76],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    className: 'leaflet-venue-icon'
  });

  parseDateTime = (dateTime) => {
    const [date, time] = dateTime.split('T')
  }

  getStartTime = (event) => {
    this.parseDateTime(event.target.value)
    this.setState({startTime: event.target.value+':00Z'})

  }

  getEndTime = (event) => {
    this.parseDateTime(event.target.value)
    this.setState({endTime: event.target.value+':00Z'})
  }

  startVoyage = () => {
    this.stop = false;
    if(this.count === this.state.positions3.length){
      this.count = -2;
    }
    this.repeat();
  }

  saveTime = () => {
    this.fetchdetections()
    this.count = 0;
    this.stopVoyage();
  }

  stopVoyage = () => {
    this.stop = true;
  }

  ChangeMapView = ({ coords }) => {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
  }

  speedDown = () => {
    this.setState({speed: this.state.speed + 400, speedAlert: ''})
  }

  speedUp = () => {
    if(this.state.speed >= 800)
      this.setState({speed: this.state.speed - 400, speedAlert: ''})
    else
      this.setState({speedAlert: 'HighestSpeed'})
  }

  render() {
    return (
      <div className="voyagePage">
        <h2> Voyage {this.boatName} </h2>
        <MapContainer className="map-container2" id="voyageMap" center={[this.state.start_pos[0], this.state.start_pos[1]]} zoom={10} minZoom={4}>
          <TileLayer
            attribution={this.Esri_WorldStreetMapAttr}
            url={this.Esri_WorldStreetMap}
          />
          { this.state.empty ? <></> : <ReactLeafletDriftMarker
            position={this.state.latlng}
            duration={2000}
          >
          <this.ChangeMapView coords={[this.state.start_pos[0], this.state.start_pos[1]]} />
            <Tooltip permanent>{this.positions!== undefined && this.positions[this.count] !== undefined ? 
            findGradesMinutes(Number(this.positions[this.count][1]), true) + ' ' + 
            findGradesMinutes(Number(this.positions[this.count][2]), false) + ' ' +
            data.Voyage.At[this.props.language] + ' ' + parseDateTime(this.positions[this.count][0]) : ''}
             </Tooltip>
            </ReactLeafletDriftMarker>} {this.state.empty ? '' : this.state.polyline}
        </MapContainer>
        <br />
        {this.state.empty? <Alert severity="error">{data.Alerts.NoPositionsVoyage[this.props.language]}</Alert> : ''}
        {this.state.empty? <></> : 
          <p> 
            <b> {this.positions[this.count] !== undefined ? data.Voyage.Latitude[this.props.language] + ' ' +
            findGradesMinutes(Number(this.positions[this.count][1]), true) + ' , ' +
            data.Voyage.Longitude[this.props.language] + ': ' +
            findGradesMinutes(Number(this.positions[this.count][2]), false) + ' ' +
            data.Voyage.At[this.props.language] + ' ' + parseDateTime(this.positions[this.count][0]) : ''}
            </b> 
          </p>
        }
        <Stack className="dateTimeBoxes" component="form" noValidate spacing={3}>
          <TextField
            id="datetime-local"
            label={data.Voyage.StartFrom[this.props.language]}
            type="datetime-local"
            defaultValue=""
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.getStartTime}
          />
          <TextField
            id="datetime-local"
            label={data.Voyage.To[this.props.language]}
            type="datetime-local"
            defaultValue=""
            sx={{ width: 250 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.getEndTime} 
          />
        </Stack>
        <Button variant="contained" 
          sx={{ bgcolor: theme.palette.secondary.dark, color: theme.palette.info.contrastText,
            ":hover":{
                bgcolor: theme.palette.info.main
            }}}
            onClick={this.saveTime}
            >{data.Voyage.SaveDate[this.props.language]}</Button>
        <br/>
        <Button variant="contained" 
          sx={{ bgcolor: 'green', color: theme.palette.info.contrastText, 
          ":hover":{
            bgcolor: theme.palette.info.dark
        }}}
            onClick={this.startVoyage}
            >{data.Voyage.Start[this.props.language]}</Button>

        <Button variant="contained" 
          sx={{ bgcolor: theme.palette.secondary.main, color: theme.palette.info.contrastText,
          ":hover":{
              bgcolor: theme.palette.info.main
          }}}
          onClick={this.stopVoyage}
          >{data.Voyage.Stop[this.props.language]}</Button>

        <br/>
        <Button variant="contained" 
          sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.dark,
          ":hover":{
              bgcolor: theme.palette.primary.light
          }}}
          onClick={this.speedUp}
          >{data.Voyage.SpeedUp[this.props.language]}</Button>

        <Button variant="contained" 
          sx={{ bgcolor: theme.palette.primary.dark, color: theme.palette.primary.light,
          ":hover":{
              bgcolor: theme.palette.primary.dark
          }}}
          onClick={this.speedDown}
          >{data.Voyage.SpeedDown[this.props.language]}</Button>
        <p> {this.state.speedAlert !== '' ? data.Voyage[this.state.speedAlert][this.props.language] : ''} </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boatName: state.boat.boatName,
    boatId: state.boat.boatId,
    boatPos: state.boat.boatPos,
    token: state.user.token,
    language: state.user.language_name,
  }
}

export default connect(mapStateToProps)(Voyage);

