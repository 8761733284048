import {createSlice} from '@reduxjs/toolkit';
import moment from 'moment';
import { Boat__ } from './../../Components/SubComponents/Types';

interface IboatReducer {
    boats: string[],
    expiry: string,
    boatName: string,
    boatId: String,
    redirect: boolean,
    boatDetails: Boat__ | undefined,
    confirmed: boolean,
}

const initialState: IboatReducer = {
    boats: [],
    expiry: moment().subtract(1, 'days').format(),
    boatName: "",
    boatId: "",
    redirect: false,
    boatDetails: undefined,
    confirmed: false,
}

export const boatSlice = createSlice({
    name: 'boats',
    initialState,
    reducers: {
        saveBoatList: (state, action) => {
            // action payload have response from axios request which have data
            state.boats = action.payload.data;
            state.expiry = moment().add(1, 'days').format();
        },
        saveRecievedBoatDetails: (state, action) => {
            state.boatDetails = action.payload;
        },
        putConfirmed: (state, action) => {
            state.confirmed = action.payload;
        },
        saveChosenBoatFromMenu: (state, action) => {
            // action payload have label(name) and id
            if(state.boatName !== action.payload.label){
                state.redirect = true;
            }
            else{
                state.redirect = false;
            }
            state.boatName = action.payload.label;
            state.boatId = action.payload.id;
        },
        clearRecievedBoatDetails: (state) => {
            state.boatDetails = undefined;
            state.boatName = "";
            state.boatId =  "";
        },
    }
})
export const {saveBoatList, saveChosenBoatFromMenu, saveRecievedBoatDetails, clearRecievedBoatDetails, putConfirmed} = boatSlice.actions;
export default boatSlice.reducer;