import React from "react";
import {Descriptions, Tag, Tooltip} from 'antd';
import {IConfirmationProps} from "../../../Types/Registration";
import {camelCaseToTitleCase} from "../../../utils";
import PrevNext from "../PrevNext";
import {EditOutlined} from "@ant-design/icons"
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import Alert from "@mui/material/Alert";
import data from '../../../utils/languages.json';


function Confirmation(props: IConfirmationProps) {
    const {
        dealerInformation = {},
        boatInformation = {},
        buyerInformation = {},
        prev,
        done,
        current,
        stepsLength,
        changeCurrentTab
    } = props;
    
    const language = useSelector((state: RootState) => state.user.language_name);
    const confirmed = useSelector((state: RootState) => state.boats.confirmed);

    const formatBuyerInformation = (key: string, value: any) => {
        if (typeof value === "string") {
            return value
        } else if (Array.isArray(value)) {
            console.log(key, value)
            return (
                <div>
                    {value.map((item, index) => { 
                        console.log(item)
                        return(
                        <Tag key={index} color="magenta">
                            {Object.keys(item).map((info, index) => (
                                <div key={index}
                                     color="magenta">
                                    {`${data.SellerApp.BuyerInfo[key][info][language]}: ${formatBuyerInformation(info, item[info])}`}
                                    {Object.keys(item).length - 1 === index ? null : ", "}
                                </div>
                            ))}
                        </Tag>
                    )})}
                </div>
            )
        }
        return null
    }

    const formatDescriptionTitle = (title: string, current: number) => {
        return (
            <div className="d-flex flex-row">
                <div className="w-25">{data.SellerApp[title][language]}</div>
                <div>
                    <Tooltip title={`${data.SellerApp.Messages.Edit[language]} ${data.SellerApp[title][language]}`}>
                        <EditOutlined className="text-primary" onClick={() => changeCurrentTab(current)}/>
                    </Tooltip>
                </div>
            </div>
        )
    }

    return (
        <>
            <Descriptions bordered className="mb-4"
                          title={formatDescriptionTitle("Dealer Information", 0)}
                          column={2}
            >
                {Object.keys(dealerInformation).map((item, i) => (
                    <Descriptions.Item
                        key={i}
                        label={data.SellerApp.DealerInfo[item][language]}
                    >
                        {dealerInformation[item]}
                    </Descriptions.Item>
                ))}
            </Descriptions>

            <Descriptions bordered className="mb-4"
                          title={formatDescriptionTitle("Boat Information", 1)}
                          column={1}
            >
                {Object.keys(boatInformation).map((item, i) => {
                    if(typeof boatInformation[item] === "string")
                        return(
                        <Descriptions.Item
                            key={i}
                            label={data.SellerApp.BoatInfo[item][language]}
                        >
                            {boatInformation[item]}
                        </Descriptions.Item>)
                })}
            </Descriptions>


            <Descriptions bordered className="mb-4"
                          title={formatDescriptionTitle("Buyer Information", 2)}
                          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                         >
                {Object.keys(buyerInformation).map((item, i) => (
                    <Descriptions.Item
                        key={i}
                        label={item === 'buyerName' || item === 'buyerPhone' || item === 'buyerEmail' ? 
                        data.SellerApp.BuyerInfo[item][language] : data.SellerApp.BuyerInfo[item][item][language] }>
                        <>
                            {formatBuyerInformation(item, buyerInformation[item])}
                        </>
                    </Descriptions.Item>
                ))}
            </Descriptions>
            <PrevNext current={current} prev={prev} stepsLength={stepsLength} done={done}/>
            {confirmed ? <Alert severity="success">{data.SellerApp.Messages.Registered[language]}</Alert> 
            : <Alert severity="error">{data.SellerApp.Messages.NotRegistered[language]}</Alert>}

        </>
    );
}

export default Confirmation;