import {
    IBuyerInformation,
    IStepsChildren,
} from "../../../Types/Registration";
import InputWithLabel from "../InputWithLabel";
import PrevNext from "../PrevNext";
import {Form, Divider} from "antd";
import DynamicForm from "../DynamicForm";
import {dynamicFields} from "./BuyerUtils";
import {FormFields} from "./BuyerUtils";


function BuyerInformation(props: IStepsChildren<IBuyerInformation>) {
    const {current, prev, next, stepsLength, data, setData} = props;

    const onFinish = (values: IBuyerInformation) => {
        //console.log("Success:", values);
        setData(values);
        next()
    };

    const onFinishFailed = (errorInfo: any) => {
        //console.log("Failed:", errorInfo);
    };
    return (
        <div>
            <Form
                name="basic"
                initialValues={{remember: true, ...data}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className="container">
                    <div className="row">
                        {FormFields.map((item) => (
                            <div key={item.name} className="col-md-6">
                                <InputWithLabel {...item} />
                            </div>
                        ))}
                    </div>
                    <Divider/>
                    {dynamicFields.map((item, index) => {
                    return (
                        <div key={index} className="buyerList">
                            
                            <DynamicForm {...item} />
                        </div>
                    )})}
                </div>

                <PrevNext current={current} prev={prev} stepsLength={stepsLength}/>
            </Form>
        </div>
    );
}

export default BuyerInformation;
